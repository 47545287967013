import torba_1 from "../images/kartony_transportowe/karton_transportowy_22_22_6.jpg";
import torba_2 from "../images/kartony_transportowe/karton_transportowy_25_15_8.jpeg";
import torba_3 from "../images/kartony_transportowe/karton_transportowy_25_15_8.jpeg";
import torba_4 from "../images/kartony_transportowe/karton_transportowy_31_45_8.jpg";
import torba_5 from "../images/kartony_transportowe/karton_transportowy_31_45_8.jpg";
import paczek_duzy from "../images/kartony_transportowe/paczek_duzy.jpg";
import karton_lisc from "../images/kartony_transportowe/karton_lisc.jpg";

const products = [
  {
    id: 1,
    name: "Pudełko transportowe z okienkiem 22,5x22,5x6",
    width: 225,
    length: 225,
    height: 60,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 2,
    name: "Pudełko transportowe z okienkiem 25,5x15,3x8",
    width: 153,
    length: 255,
    height: 80,
    color: "Brązowy",
    amount: 10,
    image: torba_2,
  },
  {
    id: 3,
    name: "Pudełko transportowe z okienkiem 35,9x25,2x8",
    width: 252,
    length: 359,
    height: 80,
    color: "Brązowy",
    amount: 10,
    image: torba_3,
  },
  {
    id: 4,
    name: "Pudełko transportowe z okienkiem 45x31x8",
    width: 310,
    length: 450,
    height: 80,
    color: "Brązowy",
    amount: 10,
    image: torba_4,
  },
  {
    id: 5,
    name: "Pudełko transportowe z okienkiem 55,8x25,2x8",
    width: 252,
    length: 558,
    height: 80,
    color: "Brązowy",
    amount: 10,
    image: torba_5,
  },
  {
    id: 6,
    name: "Pudełko transportowe z liściem 24,7x35,7x8",
    width: 247,
    length: 357,
    height: 80,
    color: "Brązowy",
    amount: 10,
    image: karton_lisc,
  },
  {
    id: 7,
    name: "Kasta pączek duży 600x380x90",
    width: 600,
    length: 380,
    height: 90,
    color: "Brązowy",
    amount: 10,
    image: paczek_duzy,
  },
];

export { products };
