import miseczka_chinet from "../images/miseczki/miseczka_chinet.jpg";
import flaczarka from "../images/miseczki/flaczarka.jpg";
import zupka_460 from "../images/miseczki/zupka_460.jpg";

const products = [
  {
    id: 1,
    name: "Miseczka chinet 350ml",
    capacity: 350,
    material: "Trzcina cukrowa",
    amunt: 50,
    image: miseczka_chinet,
  },
  {
    id: 2,
    name: "Miseczka chinet 500ml",
    capacity: 500,
    material: "Trzcina cukrowa",
    amunt: 50,
    image: miseczka_chinet,
  },
  {
    id: 3,
    name: "Flaczarka plastikowa 350ml",
    capacity: 350,
    material: "PP",
    amunt: 100,
    image: flaczarka,
  },
  {
    id: 4,
    name: "Flaczarka plastikowa 500ml",
    capacity: 500,
    amunt: 100,
    material: "PP",
    image: flaczarka,
  },
  {
    id: 5,
    name: "Miseczka styropianowa 460ml",
    capacity: 460,
    material: "Styropian",
    amunt: 25,
    image: zupka_460,
  },
  {
    id: 6,
    name: "Miseczka styropianowa 570ml",
    capacity: 570,
    material: "Styropian",
    amunt: 25,
    image: zupka_460,
  },
];

export { products };
