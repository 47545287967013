import Carousel from "react-bootstrap/Carousel";
import kubki from "../images/k.jpg";
import pojemnik from "../images/p.jpg";
import ciastka from "../images/c.jpg";

function Slides() {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100 my-slide-photo"
          // style={{ width: 2000, paddingBottom: 800 }}
          src={kubki}
          alt="First slide"
        />
        <Carousel.Caption>
          {/* <h3>25 lat doświadczenia.</h3> */}
          <p>Ekologiczne opakowania</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 my-slide-photo"
          // style={{ height: 500 }}
          src={pojemnik}
          alt="Second slide"
        />
        <Carousel.Caption>
          {/* <h3>Bogaty wybór.</h3> */}
          <p>Szeroki wybór asortymentu</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 my-slide-photo"
          // style={{ height: 500 }}
          src={ciastka}
          alt="Third slide"
        />
        <Carousel.Caption>
          {/* <h3>First slide label</h3> */}
          <p>25 lat doświadczenia</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slides;
