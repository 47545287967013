import podajnik_zz from "../images/art_gospodarcze/podajnik_zz.png";
import podajnik_jumbo from "../images/art_gospodarcze/podajnik_jumbo.jpg";
import podajnik_maxi from "../images/art_gospodarcze/podajnik_maxi.jpg";
import dozownik_mydla from "../images/art_gospodarcze/dozownik_mydla.jpg";
import kosz from "../images/art_gospodarcze/kosz.jpg";
import kosz_10 from "../images/art_gospodarcze/kosz_10.jpg";

const products = [
  {
    id: 1,
    name: "Podajnik do ręcznika Z-Z",
    image: podajnik_zz,
  },
  {
    id: 2,
    name: "Podajnik do papieru toaletowego jumbo",
    image: podajnik_jumbo,
  },
  {
    id: 3,
    name: "Podajnik do ręcznika papierowego maxi",
    image: podajnik_maxi,
  },
  {
    id: 4,
    name: "Dozownik mydła w płynie",
    image: dozownik_mydla,
  },
  {
    id: 5,
    name: "Kosz na śmieci 50L 30x22x53cm",
    image: kosz,
  },
  {
    id: 6,
    name: "Kosz na śmieci 35L 21x29x43cm",
    image: kosz,
  },
  {
    id: 7,
    name: "Kosz na śmieci 10L 18x13x29cm",
    image: kosz_10,
  },
];

export { products };
