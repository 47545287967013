import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Szukaj from "./Szukaj";

import { AiOutlineSearch } from "react-icons/ai";

export default function Navigation() {
  const navigate = useNavigate();

  const handleSubmit = () => {
    const input = document.getElementById("myInput").value;
    console.log(input);
    navigate(`/szukaj?parametr=${input}`);
  };

  return (
    <div className="row">
      <Navbar bg="primary" variant="dark" expand="sm">
        <Container fluid className="my-nav-container">
          <Navbar.Brand as={Link} to="/" className="primary">
            Hurtownia Opakowań
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/start">
                Start
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/produkty">Produkty</Nav.Link> */}
              <NavDropdown title="Produkty" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/produkty">
                  Wszystkie
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/torby-papierowe-z-uchem">
                  Torby papierowe z uchem
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/torebki-papierowe">
                  Torebki papierowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/torebki-krzyzowe">
                  Torebki krzyżowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/kartony-transportowe">
                  Kartony transportowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/tace-kateringowe">
                  Tace kateringowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pojemniki-pod-zgrzew">
                  Pojemniki pod zgrzew
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pojemniki-okragle-pod-zgrzew">
                  Pojemniki okrągłe
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/przykrycia-do-pojemnikow-pod-zgrzew"
                >
                  Przykrycia do pojemników pod zgrzew
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pojemniki-styropianowe">
                  Pojemniki styropianowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/kubeczki-papierowe">
                  Kubeczki papierowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pojemniki-kraft">
                  Pojemniki kraft
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/talerze">
                  Talerze
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pizza">
                  Kartony na pizze
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/opakowania-przemyslowe">
                  Opakowania przemysłowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/wiaderka">
                  Wiaderka
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pojemniki-cukiernicze">
                  Pojemniki cukiernicze
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pudelka-na-torty">
                  Pudełka na torty
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/foremki-aluminiowe">
                  Foremki aluminiowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pojemniki-salatkowe">
                  Pojemniki sałatkowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/torebki-prozniowe">
                  Torebki do pakowania próżniowego
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/rekawiczki">
                  Rękawiczki
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/reklamowki-foliowe">
                  Reklamówki foliowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/serwetki">
                  Serwetki
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/miseczki-na-zupe">
                  Miseczki na zupę
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/artykuly-gospodarcze">
                  Artykuły gospodarcze
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/odziez-gastronomiczna">
                  Odzież gastronomiczna
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/chemia-gospodarcza">
                  Chemia gospodarcza
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pudelka-na-sushi">
                  Pudełka na sushi
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/sprzatanie">
                  Sprzątanie
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/reczniki">
                  Ręczniki
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/kartony-klapowe">
                  Kartony klapowe/paczkomatowe
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/kartoniki-koperty-owijki">
                  Kartoniki, kubełki, koperty na żywność
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/shake-lody">
                  Kubki shake, miseczki na lody
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/katalog">
                Katalogi
              </Nav.Link>
              <Nav.Link as={Link} to="/kontakt">
                Kontakt
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/szukaj">
                Szukaj
              </Nav.Link> */}
            </Nav>

            <div className="search-button d-flex">
              <Nav.Link as={Link} to="/szukaj">
                <Button variant="light">
                  Znajdź produkt{" "}
                  <AiOutlineSearch style={{ fontSize: "23px" }} />
                </Button>
              </Nav.Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
