import folia_stretch_czarna from "../images/opakowania_przemyslowe/folia_stretch_czarna.jpg";
import folia_stretch_bezbarwna from "../images/opakowania_przemyslowe/folia_stretch_bezbarwna.jpg";
import folia_stretch_niebieska from "../images/opakowania_przemyslowe/folia_stretch_niebieska.jpg";
import folia_stretch_biala from "../images/opakowania_przemyslowe/folia_stretch_biala.jpg";
import granat_z_raczka from "../images/opakowania_przemyslowe/granat_z_raczka.jpg";
import granat_bez_raczki from "../images/opakowania_przemyslowe/granat_bez_raczki.jpg";
import folia_stretch_maszynowa from "../images/opakowania_przemyslowe/folia_stretch_maszynowa.jpg";
import tektura_falista from "../images/opakowania_przemyslowe/tektura_falista.jpeg";
import pianka from "../images/opakowania_przemyslowe/pianka.jpg";
import folia_pecherzykowa from "../images/opakowania_przemyslowe/folia_pecherzykowa.jpg";
import tasma_pp from "../images/opakowania_przemyslowe/tasma_pp.jpg";
import paskowacz from "../images/opakowania_przemyslowe/paskowacz.png";
import zapinka from "../images/opakowania_przemyslowe/zapinka.jpg";
import druty from "../images/opakowania_przemyslowe/druty.jpg";
import napinacz from "../images/opakowania_przemyslowe/napinacz.jpg";

const products = [
    {
        "id": 1,
        "name": "Folia stretch 2,5kg czarna",
        "weight": 2.5,
        "width": 500,
        "thickness": 23,
        "color": "Czarny",
        "type": "Stretch",
        "image": folia_stretch_czarna
    },
    {
        "id": 2,
        "name": "Folia stretch 2,5kg bezbarwna",
        "weight": 2.5,
        "width": 500,
        "thickness": 23,
        "color": "Bezbarwny",
        "type": "Stretch",
        "image": folia_stretch_bezbarwna
    },
    {
        "id": 3,
        "name": "Folia stretch 2,5kg niebieska",
        "weight": 2.5,
        "width": 500,
        "thickness": 23,
        "color": "Niebieski",
        "type": "Stretch",
        "image": folia_stretch_niebieska
    },
    {
        "id": 4,
        "name": "Folia stretch 2,5kg biała",
        "weight": 2.5,
        "width": 500,
        "thickness": 23,
        "color": "Biały",
        "type": "Stretch",
        "image": folia_stretch_biala
    },
    {
        "id": 5,
        "name": "Folia stretch 1,5kg bezbarwna",
        "weight": 2.5,
        "width": 500,
        "thickness": 23,
        "color": "bezbarwna",
        "type": "Stretch",
        "image": folia_stretch_bezbarwna
    },
    {
        "id": 6,
        "name": "Folia stretch granat z rączką",
        "weight": 0.35,
        "width": 100,
        "thickness": 23,
        "color": "Bezbarwny",
        "type": "Stretch",
        "image": granat_z_raczka
    },
    {
        "id": 7,
        "name": "Folia stretch granat bez rączki",
        "weight": 0.3,
        "width": 100,
        "thickness": 23,
        "color": "Bezbarwny",
        "type": "Stretch",
        "image": granat_bez_raczki
    },
    {
        "id": 8,
        "name": "Folia stretch maszynowa 17,5kg",
        "weight": 17.5,
        "width": 500,
        "thickness": 23,
        "color": "Bezbarwny",
        "type": "Stretch",
        "image": folia_stretch_maszynowa
    },
    {
        "id": 9,
        "name": "Tektura falista 20m²",
        "height": 1.05,
        "lenght": 19,
        "thickness": 2,
        "type": "Tektura",
        "image": tektura_falista
    },
    {
        "id": 10,
        "name": "Tektura falista 52.5m²",
        "height": 1.05,
        "lenght": 50,
        "thickness": 2,
        "type": "Tektura",
        "image": tektura_falista
    },
    {
        "id": 11,
        "name": "Tektura falista 105m²",
        "height": 1.05,
        "lenght": 100,
        "thickness": 2,
        "type": "Tektura",
        "image": tektura_falista
    },
    {
        "id": 12,
        "name": "Pianka polietylenowa 0.8mm",
        "height": 1.25,
        "lenght": 400,
        "thickness": "0.8",
        "type": "Pianka",
        "image": pianka
    },
    {
        "id": 13,
        "name": "Pianka polietylenowa 2.0mm",
        "height": 1.25,
        "lenght": 250,
        "thickness": "2.0",
        "type": "Pianka",
        "image": pianka
    },
    {
        "id": 14,
        "name": "Pianka polietylenowa 3.0mm",
        "height": 1.25,
        "lenght": 175,
        "thickness": "3.0",
        "type": "Pianka",
        "image": pianka
    },
    {
        "id": 15,
        "name": "Folia pęcherzykowa 40cm",
        "width": 40,
        "lenght": 100,
        "thickness": 2,
        "type": "Bąbel",
        "image": folia_pecherzykowa
    },
    {
        "id": 16,
        "name": "Folia pęcherzykowa 100cm",
        "width": 100,
        "lenght": 100,
        "thickness": 2,
        "type": "Bąbel",
        "image": folia_pecherzykowa
    },
    {
        "id": 17,
        "name": "Folia pęcherzykowa 120cm",
        "width": 120,
        "lenght": 100,
        "thickness": 2,
        "type": "Bąbel",
        "image": folia_pecherzykowa
    },
    {
        "id": 18,
        "name": "Folia pęcherzykowa 150cm",
        "width": 150,
        "lenght": 100,
        "thickness": 2,
        "type": "Bąbel",
        "image": folia_pecherzykowa
    },
    {
        "id": 19,
        "name": "Taśma PP",
        "width": "12, 16, 19 mm",
        "lenght": "1000 m",
        "type": "tasma",
        "image": tasma_pp
    },
    {
        "id": 20,
        "name": "Paskowacz do taśmy PP",
        "width": "-",
        "lenght": "-",
        "type": "tasma",
        "image": paskowacz
    },
    {
        "id": 21,
        "name": "Napinacz do taśmy PP",
        "width": "-",
        "lenght": "-",
        "type": "tasma",
        "image": napinacz
    },
    {
        "id": 22,
        "name": "Zapinka do paskowacza",
        "width": "-",
        "lenght": "-",
        "type": "tasma",
        "image": zapinka
    },
    {
        "id": 23,
        "name": "Drut do napinacza",
        "width": "-",
        "lenght": "-",
        "type": "tasma",
        "image": druty
    }
]

export {products};