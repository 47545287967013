import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products } from "../products/odziez_data.js";

export default function Odziez() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr />
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  return (
    <div className="col container_column">
      <Helmet>
        <title>
          Odzież gastronomiczna - Hurtownia Opakowań Henryk Klimowicz
        </title>
        <meta name="description" content="Odzież gastronomiczna." />
        <link rel="canonical" href="/odziez-gastronomiczna" />
      </Helmet>

      <ProductHeader product_name="Odzież gastronomiczna"></ProductHeader>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
