import maseczka from "../images/odziez/maseczka.jpg";
import fartuch_foliowy from "../images/odziez/fartuch_foliowy.jpg";
import fartuch_fizelinowy from "../images/odziez/fartuch_fizelinowy.jpg";
import czepek from "../images/odziez/czepek.jpg";
import zarekawki from "../images/odziez/zarekawki.jpg";
import ochraniacze_na_obuwie from "../images/odziez/ochraniacze_na_obuwie.jpg";

const products = [
  {
    id: 1,
    name: "Maseczka jednorazowa",
    image: maseczka,
  },
  {
    id: 2,
    name: "Fartuch foliowy",
    image: fartuch_foliowy,
  },
  {
    id: 3,
    name: "Fartuch włókninowy",
    image: fartuch_fizelinowy,
  },
  {
    id: 4,
    name: "Czepek jednorazowy clip",
    image: czepek,
  },
  {
    id: 5,
    name: "Zarękawki",
    image: zarekawki,
  },
  {
    id: 6,
    name: "Ochraniacze na obuwie",
    image: ochraniacze_na_obuwie,
  },
];

export { products };
