import folia_pod_zgrzew from "../images/pojemniki_do_zgrzewu/folia_pod_zgrzew.jpeg";
import przykrywka_pod_zgrzew from "../images/pojemniki_do_zgrzewu/przykrywka_pod_zgrzewu.jpg";

const products = [
  {
    id: 1,
    name: "Przykrywka do pojemnika pod zgrzew 227x178x20",
    length: 227,
    width: 178,
    height: 20,
    type: "Przykrywka",
    color: "Bezbarwny",
    amount: 50,
    image: przykrywka_pod_zgrzew,
  },
  {
    id: 2,
    name: "Folia PET/PP pod zgrzew lintop 250 mb 185x250",
    length: 250,
    width: 185,
    thickness: 50,
    type: "Folia",
    color: "Bezbarwny",
    amount: 1,
    image: folia_pod_zgrzew,
  },
  {
    id: 3,
    name: "Folia PET/PP pod zgrzew 250 mb 460x250",
    length: 250,
    width: 460,
    thickness: 50,
    type: "Folia",
    color: "Bezbarwny",
    amount: 1,
    image: folia_pod_zgrzew,
  },
  {
    id: 4,
    name: "Folia PP pod zgrzew 190/50my",
    length: 250,
    width: 190,
    thickness: 50,
    type: "Folia",
    color: "Bezbarwny",
    amount: 1,
    image: folia_pod_zgrzew,
  },
  {
    id: 5,
    name: "Przykrywka W2/601 do pojemnika pod zgrzew",
    length: 190,
    width: 148,
    height: 10,
    type: "Przykrywka",
    color: "Bezbarwny",
    amount: 640,
    image: przykrywka_pod_zgrzew,
  },
];

export { products };
