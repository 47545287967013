import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";
import { products } from "../products/reczniki_data.js";
import Accordion from "react-bootstrap/Accordion";

export default function Reczniki() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr style={{ border: "1px solid #0275d8" }} />
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  const filter = (e) => {
    const type = document.getElementById("select_type").value;

    var p = products.filter((product) => type === "" || product.type == type);

    set_filtered_products(p);
    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>Ręczniki papierowe - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Ręczniki papierowe, papiery toaletowe, czyściwa"
        />
        <link rel="canonical" href="/reczniki" />
      </Helmet>

      <ProductHeader product_name="Ręczniki"></ProductHeader>

      <div className="row my-accodrion-row">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filtruj produkty</Accordion.Header>
            <Accordion.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Rodzaj</InputGroup.Text>
                    <Form.Select
                      id="select_type"
                      aria-label="select_type"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="recznik">Ręczniki</option>
                      <option value="czysciwo">Czyściwa</option>
                      <option value="pt">Papier toaletowy</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
