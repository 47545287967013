import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products } from "../products/podklady_pod_tort.js";

export default function PodkladyPodTort() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr />
          <Card.Text>
            <b>Wymiar:</b> {product.diameter}
            <br />
            <b>Kształt:</b> {product.shape}
            <br />
            <b>Materiał:</b> {product.material}
            <br />
            <b>Kolor:</b> {product.color}
            <br />
            <b>Pakowane po:</b> {product.amount} sztuk
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  const sort = (e) => {
    const option = document.getElementById("select_sort").value;

    console.log("moja opcja" + option);
    var sorted_products;

    if (option === "1") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.id >= p2.id ? 1 : -1
      );
    } else if (option === "2") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.diameter >= p2.diameter ? 1 : -1
      );
    } else if (option === "3") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.diameter >= p2.diameter ? -1 : 1
      );
    }

    console.log(sorted_products);

    set_list_of_products(map_products(sorted_products));
  };

  const filter = (e) => {
    const shape = document.getElementById("select_shape").value;

    const type = document.getElementById("select_type").value;

    const material = document.getElementById("select_material").value;

    // const diameter = document
    //     .getElementById("select_diameter")
    //     .value;

    var p = products.filter(
      (product) =>
        // (diameter === "" || product.diameter == diameter) &&
        (type === "" || product.type == type) &&
        (material === "" || product.material == material) &&
        (shape === "" || product.shape == shape)
    );

    set_filtered_products(p);
    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>Podkłady pod tort - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Podkłady pod tort do wyściełania opakowań."
        />
        <link rel="canonical" href="/podklady-pod-tort" />
      </Helmet>

      <ProductHeader product_name="Podkłady pod tort"></ProductHeader>

      <div className="row my-accodrion-row">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filtruj produkty</Accordion.Header>
            <Accordion.Body style={{ "margin-top": 20 }}>
              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Typ</InputGroup.Text>
                    <Form.Select
                      id="select_type"
                      aria-label="select_type"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="Podkład">Podkład</option>
                      <option value="Serwetka">Serwetka</option>
                    </Form.Select>
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Kształt</InputGroup.Text>
                    <Form.Select
                      id="select_shape"
                      aria-label="select_shape"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="Okrągły">Okrągły</option>
                      <option value="Prostokątny">Prostokątny</option>
                    </Form.Select>
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Materiał</InputGroup.Text>
                    <Form.Select
                      id="select_material"
                      aria-label="select_material"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="Papier">Papier</option>
                      <option value="PP">PP</option>
                    </Form.Select>
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Sortowanie</InputGroup.Text>
                    <Form.Select id="select_sort" onChange={(e) => sort(e)}>
                      <option value="1">Domyślne</option>
                      <option value="2">Rozmiar rosnąco</option>
                      <option value="3">Rozmiar malejąco</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
