import patera_aluminiowa from "../images/tace_kateringowe/patera_aluminiowa.jpg";
import taca_kateringowa_czarna from "../images/tace_kateringowe/taca_kateringowa_czarna.jpg";
import pokrywka_do_tacy_kateringowej from "../images/tace_kateringowe/pokrywka_do_tacy_kateringowej.jpg";
import chinet_owalny from "../images/talerze/chinet_owalny.jpg";

const products = [
  {
    id: 1,
    name: "Patera aluminiowa mała 334x234x21",
    length: 334,
    width: 234,
    height: 21,
    material: "Aluminium",
    type: "Taca",
    color: "Srebrny",
    amount: 10,
    image: patera_aluminiowa,
  },
  {
    id: 2,
    name: "Patera aluminiowa średnia 446x296x25",
    length: 446,
    width: 296,
    height: 25,
    material: "Aluminium",
    type: "Taca",
    color: "Srebrny",
    amount: 10,
    image: patera_aluminiowa,
  },
  {
    id: 3,
    name: "Patera aluminiowa duża 550x360x22",
    length: 550,
    width: 360,
    height: 22,
    material: "Aluminium",
    type: "Taca",
    color: "Srebrny",
    amount: 10,
    image: patera_aluminiowa,
  },
  {
    id: 4,
    name: "Taca kateringowa mała 350x247x20",
    length: 350,
    width: 247,
    height: 20,
    material: "PET",
    type: "Taca",
    color: "Czarny",
    amount: 10,
    image: taca_kateringowa_czarna,
  },
  {
    id: 5,
    name: "Przykrywka do tacy kateringowej małej",
    length: 360,
    width: 260,
    height: 65,
    material: "PET",
    type: "Pokrywka",
    color: "Bezbarwny",
    amount: 10,
    image: pokrywka_do_tacy_kateringowej,
  },
  {
    id: 6,
    name: "Taca kateringowa średnia 450x300x25",
    length: 450,
    width: 300,
    height: 25,
    material: "PET",
    type: "Taca",
    color: "Czarny",
    amount: 10,
    image: taca_kateringowa_czarna,
  },
  {
    id: 7,
    name: "Przykrywka do tacy kateringowej średniej",
    length: 465,
    width: 312,
    height: 65,
    material: "PET",
    type: "Pokrywka",
    color: "Bezbarwny",
    amount: 10,
    image: pokrywka_do_tacy_kateringowej,
  },
  {
    id: 8,
    name: "Taca kateringowa duża 550x360x30",
    length: 550,
    width: 360,
    height: 30,
    material: "PET",
    type: "Taca",
    color: "Czarny",
    amount: 10,
    image: taca_kateringowa_czarna,
  },
  {
    id: 9,
    name: "Przykrywka do tacy kateringowej dużej",
    length: 550,
    width: 370,
    height: 65,
    material: "PET",
    type: "Pokrywka",
    color: "Bezbarwny",
    amount: 10,
    image: pokrywka_do_tacy_kateringowej,
  },
  {
    id: 10,
    name: "Talerz chinet owalny mały ∅26",
    length: 260,
    width: 190,
    height: 10,
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_owalny,
  },
  {
    id: 5,
    name: "Talerz chinet owalny duży ∅32",
    length: 320,
    width: 250,
    height: 10,
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_owalny,
  },
];

export { products };
