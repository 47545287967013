import stelaz_plaski from "../images/sprzatanie/stelaz_plaski.jpg";
import mop_fredzle from "../images/sprzatanie/mop_fredzle.jpg";
import mop_gosia from "../images/sprzatanie/mop_gosia.jpg";
import mop from "../images/sprzatanie/mop.jpg";
import wozek1 from "../images/sprzatanie/wozek_1.jpg";
import wozek2 from "../images/sprzatanie/wozek_2.jpg";
import sc3 from "../images/sprzatanie/sc3.jpg";
import prima10 from "../images/sprzatanie/prima10.jpg";
import practi3 from "../images/sprzatanie/practi3.jpg";
import narolce from "../images/sprzatanie/narolce.jpg";
import sloneczko from "../images/sprzatanie/sloneczko.jpg";
import szmata_szara from "../images/sprzatanie/szmata_szara.jpg";
import szmata_biala from "../images/sprzatanie/szmata_biala.jpg";
import glass from "../images/sprzatanie/glass.jpg";
import frotte from "../images/sprzatanie/frotte.jpg";
import gosiaxxl from "../images/sprzatanie/gosiaxxl.jpg";
import aluchrom from "../images/sprzatanie/aluchrom.jpg";
import kijalu from "../images/sprzatanie/kijalu.jpg";
import kijdrewno from "../images/sprzatanie/kijdrewno.jpg";
import mieszanka from "../images/sprzatanie/mieszanka.jpg";
import mirella from "../images/sprzatanie/mirella.jpg";
import ulicowka from "../images/sprzatanie/ulicowka.jpg";
import ryzkij from "../images/sprzatanie/ryzkij.jpg";
import sciagaczka from "../images/sprzatanie/sciagaczka.jpg";

const products = [
  {
    id: 0,
    name: "Stelaż do mopa płaskiego 40cm/50cm",
    image: stelaz_plaski,
    type: "mop",
  },
  {
    id: 2,
    name: "Mop płaski 40cm/50cm",
    image: mop_fredzle,
    type: "mop",
  },
  {
    id: 3,
    name: "Mop gosia z mikrofibry",
    image: mop_gosia,
    type: "mop",
  },
  {
    id: 4,
    name: "Mop biały 200g",
    image: mop,
    type: "mop",
  },
  {
    id: 5,
    name: "Wózek do sprzątania 1-wiadrowy 17L",
    image: wozek1,
    type: "wozek",
  },
  {
    id: 6,
    name: "Wózek do sprzątania 2-wiadrowy 2x25L",
    image: wozek2,
    type: "wozek",
  },
  {
    id: 7,
    name: "Ściereczka domowa 3szt.",
    image: sc3,
    type: "sciereczka",
  },
  {
    id: 8,
    name: "Ściereczka practi 10szt.",
    image: prima10,
    type: "sciereczka",
  },
  {
    id: 9,
    name: "Ściereczka practi maxi 3szt.",
    image: practi3,
    type: "sciereczka",
  },
  {
    id: 10,
    name: "Ściereczka z mikrofibry 32x32cm",
    image: sloneczko,
    type: "sciereczka",
  },
  {
    id: 11,
    name: "Ściereczka na rolce",
    image: narolce,
    type: "sciereczka",
  },
  {
    id: 12,
    name: "Szmata do podłogi biała 10szt.",
    image: szmata_biala,
    type: "sciereczka",
  },
  {
    id: 13,
    name: "Szmata do podłogi szara 10szt.",
    image: szmata_szara,
    type: "sciereczka",
  },
  {
    id: 14,
    name: "Ściereczka do szyb i luster",
    image: glass,
    type: "sciereczka",
  },
  {
    id: 15,
    name: "Ściereczka do kurzu",
    image: frotte,
    type: "sciereczka",
  },
  {
    id: 16,
    name: "Ściereczka do podłogi Gosia XXL",
    image: gosiaxxl,
    type: "sciereczka",
  },
  {
    id: 17,
    name: "Kij alu-chrom",
    image: aluchrom,
    type: "mop",
  },
  {
    id: 18,
    name: "Kij aluminiowy",
    image: kijalu,
    type: "mop",
  },
  {
    id: 19,
    name: "Kij drewniany",
    image: kijdrewno,
    type: "mop",
  },
  {
    id: 20,
    name: "Miotła mieszanka 35/40cm",
    image: mieszanka,
    type: "miotla",
  },
  {
    id: 21,
    name: "Miotła Mirella 30cm",
    image: mirella,
    type: "miotla",
  },
  {
    id: 22,
    name: "Miotła ulicówka 40/50cm",
    image: ulicowka,
    type: "miotla",
  },
  {
    id: 23,
    name: "Szczotka ryżowa na kij",
    image: ryzkij,
    type: "miotla",
  },
  {
    id: 24,
    name: "Ściągaczka do wody 40/55/75cm",
    image: sciagaczka,
    type: "mop",
  },
];

export { products };
