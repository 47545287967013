import torba_1 from "../images/torby_papierowe/mala_brazowa_180_80_225.png";
import torba_2 from "../images/torby_papierowe/mala_biala_180_80_225.png";
import torba_3 from "../images/torby_papierowe/srednia_brazowa_240_100_320.png";
import torba_4 from "../images/torby_papierowe/srednia_biala_240_100_320.png";
import torba_5 from "../images/torby_papierowe/duza_brazowa_170_300_440.png";
import torba_6 from "../images/torby_papierowe/duza_biala_170_300_440.png";
import torba_7 from "../images/torby_papierowe/giga_330_360_320.png";
import torba_8 from "../images/torby_papierowe/mega_500_180_390.png";
import torba_9 from "../images/torby_papierowe/lomza_320_220_245.png";
import torba_10 from "../images/torby_papierowe/ucho_320_170_450.png";
import torba_11 from "../images/torby_papierowe/uchwyt_plaski_320_170_290.png";
import torba_12 from "../images/torby_papierowe/arsen_260_170_330.png";
import torba_13 from "../images/torby_papierowe/cateringowa.png";
import torba_14 from "../images/torby_papierowe/torba_z_nadrukiem.png";

const products = [
  {
    id: 1,
    name: "Torba papierowa mała",
    width: 80,
    length: 180,
    height: 225,
    color: "Brązowy",
    amount: "25 sztuk",
    image: torba_1,
  },
  {
    id: 2,
    name: "Torba papierowa mała",
    width: 80,
    length: 180,
    height: 225,
    color: "Biały",
    amount: "25 sztuk",
    image: torba_2,
  },
  {
    id: 3,
    name: "Torba papierowa średnia",
    width: 100,
    length: 240,
    height: 320,
    color: "Brązowy",
    amount: "25 sztuk",
    image: torba_3,
  },
  {
    id: 4,
    name: "Torba papierowa średnia",
    width: 100,
    length: 240,
    height: 320,
    color: "Biały",
    amount: "25 sztuk",
    image: torba_4,
  },
  {
    id: 5,
    name: "Torba papierowa duża",
    width: 170,
    length: 300,
    height: 440,
    color: "Brązowy",
    amount: "25 sztuk",
    image: torba_5,
  },
  {
    id: 6,
    name: "Torba papierowa duża",
    width: 170,
    length: 300,
    height: 440,
    color: "Biały",
    amount: "25 sztuk",
    image: torba_6,
  },
  {
    id: 7,
    name: "Torba papierowa giga",
    width: 360,
    length: 330,
    height: 320,
    color: "Brązowy",
    amount: "100 sztuk",
    image: torba_7,
  },
  {
    id: 8,
    name: "Torba papierowa mega",
    width: 500,
    length: 180,
    height: 390,
    color: "Brązowy",
    amount: "100 sztuk",
    image: torba_8,
  },
  {
    id: 9,
    name: "Torba papierowa łomża",
    width: 220,
    length: 320,
    height: 245,
    color: "Brązowy",
    amount: "250 sztuk",
    image: torba_9,
  },
  {
    id: 10,
    name: "Torba papierowa ucho",
    width: 170,
    length: 320,
    height: 450,
    color: "Brązowy",
    amount: "100 sztuk",
    image: torba_10,
  },
  {
    id: 11,
    name: "Torba papierowa uchwyt płaski",
    width: 170,
    length: 320,
    height: 290,
    color: "Brązowy",
    amount: "100 sztuk",
    image: torba_11,
  },
  {
    id: 12,
    name: "Torba papierowa arsen",
    width: 170,
    length: 260,
    height: 330,
    color: "Brązowy",
    amount: "200 sztuk",
    image: torba_12,
  },
  {
    id: 13,
    name: "Torba papierowa cateringowa",
    width: 170,
    length: 300,
    height: 340,
    color: "Brązowy",
    amount: "100 sztuk",
    image: torba_13,
  },
  {
    id: 14,
    name: "Torba papierowa z nadrukiem",
    width: 320,
    length: 170,
    height: 290,
    color: "Nadruk",
    amount: "1000 sztuk",
    image: torba_14,
  },
];

export { products };
