import R59 from "../images/foremki_aluminiowe/R59.jpg";
import R44 from "../images/foremki_aluminiowe/R44.png";
import brytwanka from "../images/foremki_aluminiowe/brytwanka.jpg";
import R61G from "../images/foremki_aluminiowe/R61G.jpg";
import foremka_1500 from "../images/foremki_aluminiowe/foremka_1500.jpg";
import c25 from "../images/foremki_aluminiowe/c25.jpg";
import c27 from "../images/foremki_aluminiowe/c27.png";

const products = [
  {
    id: 1,
    name: "Foremka aluminiowa 1000ml",
    width: "-",
    length: "-",
    height: "-",
    amount: 100,
    image: R59,
  },
  {
    id: 2,
    name: "Foremka aluminiowa 1000ml pod dekiel",
    width: "-",
    length: "-",
    height: "-",
    amount: 100,
    image: R44,
  },
  {
    id: 3,
    name: "Foremka aluminiowa 3000ml",
    width: "-",
    length: "-",
    height: "-",
    amount: 100,
    image: brytwanka,
  },
  {
    id: 4,
    name: "Foremka aluminiowa 600ml",
    width: "-",
    length: "-",
    height: "-",
    amount: 100,
    image: R61G,
  },
  {
    id: 6,
    name: "Foremka aluminiowa 1500ml",
    width: "-",
    length: "-",
    height: "-",
    amount: 100,
    image: foremka_1500,
  },
  {
    id: 7,
    name: "Foremka aluminiowa okrągła C25",
    width: "-",
    length: "-",
    height: "-",
    amount: 100,
    image: c25,
  },
  {
    id: 8,
    name: "Foremka aluminiowa okrągła C27",
    width: "-",
    length: "-",
    height: "-",
    amount: 100,
    image: c27,
  },
];

export { products };
