import kraft_zupa from "../images/pojemniki_kraft/kraft_zupa.jpg";
import kraft_500 from "../images/pojemniki_kraft/kraft_500.jpg";
import kraft_750 from "../images/pojemniki_kraft/kraft_750.jpg";
import kraft_1000 from "../images/pojemniki_kraft/kraft_1000.jpg";
import kraft_1300 from "../images/pojemniki_kraft/kraft_1300.jpg";
import przykrywka_zupa from "../images/pojemniki_kraft/przykrywka_zupa.jpg";
import przykrywka_kraft from "../images/pojemniki_kraft/przykrywka_kraft.jpg";

const products = [
  {
    id: 1,
    name: "Pojemnik na zupę kraft 450 ml",
    diameter: 97,
    capacity: 450,
    height: 100,
    color: "Brązowy",
    amount: 25,
    type: "pojemnik",
    material: "papier + PE",
    image: kraft_zupa,
  },
  {
    id: 2,
    name: "Przykrywka do pojemnika kraft ∅97",
    cup: "450",
    diameter: 97,
    color: "Brązowy",
    amount: 25,
    type: "przykrywka",
    material: "papier + PE",
    image: przykrywka_zupa,
  },
  {
    id: 3,
    name: "Pojemnik na zupę kraft 750 ml",
    diameter: 115,
    capacity: 750,
    height: 136,
    color: "Brązowy",
    amount: 25,
    type: "pojemnik",
    material: "papier + PE",
    image: kraft_zupa,
  },
  {
    id: 4,
    name: "Przykrywka do pojemnika kraft ∅115",
    cup: "750",
    diameter: 115,
    color: "Brązowy",
    amount: 25,
    type: "przykrywka",
    material: "papier + PE",
    image: przykrywka_zupa,
  },
  {
    id: 5,
    name: "Pojemnik sałatkowy kraft 500 ml",
    diameter: 149,
    capacity: 500,
    height: 50,
    color: "Brązowy",
    amount: 50,
    type: "pojemnik",
    material: "papier + PE",
    image: kraft_500,
  },
  {
    id: 6,
    name: "Pojemnik sałatkowy kraft 750 ml",
    diameter: 149,
    capacity: 750,
    height: 58,
    color: "Brązowy",
    amount: 50,
    type: "pojemnik",
    material: "papier + PE",
    image: kraft_750,
  },
  {
    id: 7,
    name: "Pojemnik sałatkowy kraft 1000 ml",
    diameter: 149,
    capacity: 1000,
    height: 78,
    color: "Brązowy",
    amount: 50,
    type: "pojemnik",
    material: "papier + PE",
    image: kraft_1000,
  },
  {
    id: 8,
    name: "Przykrywka do pojemnika kraft ∅149",
    cup: "500ml, 750ml, 1000ml",
    diameter: 149,
    color: "Bezbarwny",
    amount: 50,
    type: "przykrywka",
    material: "PET",
    image: przykrywka_kraft,
  },
  {
    id: 9,
    name: "Pojemnik sałatkowy kraft 1300 ml",
    diameter: 184,
    capacity: 1300,
    height: 70,
    color: "Brązowy",
    amount: 50,
    type: "pojemnik",
    material: "papier + PE",
    image: kraft_1300,
  },
  {
    id: 10,
    name: "Przykrywka do pojemnika kraft ∅184",
    cup: "1300ml",
    diameter: 184,
    color: "Bezbarwny",
    amount: 50,
    type: "przykrywka",
    material: "PET",
    image: przykrywka_kraft,
  },
];

export { products };
