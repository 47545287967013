import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products } from "../products/pojemniki_salatkowe_data.js";

export default function PojemnikiSalatkowe() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr />
          <Card.Text>
            <div>
              <b>Pojemność:</b> {product.capacity} ml
              <br />
              <b>Średnica górna:</b> ∅{product.diameter} mm
              <br />
              <b>Materiał:</b> {product.material}
              <br />
              <b>Z pokrywką:</b> {product.top}
              <br />
              <b>Pakowane po:</b> {product.amount} sztuk
            </div>
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  const sort = (e) => {
    const option = document.getElementById("select_sort").value;

    // console.log("moja opcja" + option);
    var sorted_products;

    if (option === "1") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.id >= p2.id ? 1 : -1
      );
    } else if (option === "2") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.capacity >= p2.capacity ? 1 : -1
      );
    } else if (option === "3") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.capacity >= p2.capacity ? -1 : 1
      );
    }

    set_list_of_products(map_products(sorted_products));
  };

  const filter = (e) => {
    // const product_type = document
    //     .getElementById("select_product_type")
    //     .value;

    const min_capacity = document
      .getElementById("form_min_capacity")
      .value.toUpperCase();
    const max_capacity = document
      .getElementById("form_max_capacity")
      .value.toUpperCase();

    var p = products.filter(
      (product) =>
        (min_capacity === "" ||
          (product.capacity != null && product.capacity >= min_capacity)) &&
        (max_capacity === "" ||
          (product.capacity != null && product.capacity <= max_capacity))
    );

    set_filtered_products(p);
    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>Pojemniki sałątkowe - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Pojemniki bezbarwne w kształcie dimantu oraz ośmiokąta na sałatkę."
        />
        <link rel="canonical" href="/pojemniki-salatkowe" />
      </Helmet>

      <ProductHeader product_name="Pojemniki sałatkowe"></ProductHeader>

      <div className="row my-accodrion-row">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filtruj produkty</Accordion.Header>
            <Accordion.Body style={{ "margin-top": 20 }}>
              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Pojemność min.</InputGroup.Text>
                    <Form.Control
                      id="form_min_capacity"
                      aria-label="min_capacity"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Pojemność max.</InputGroup.Text>
                    <Form.Control
                      id="form_max_capacity"
                      aria-label="max_capacity"
                    />
                  </InputGroup>
                </div>

                {/* <div className='col-md-3'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Typ produktu</InputGroup.Text>
                        <Form.Select id="select_product_type" aria-label="select_product_type" onChange={e => filter(e)}>
                            <option value="">Wszystko</option>
                            <option value="pojemnik">Pojemniki</option>
                            <option value="przykrywka">Przykrywki</option>
                        </Form.Select>
                    </InputGroup>
                </div> */}

                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Sortowanie</InputGroup.Text>
                    <Form.Select id="select_sort" onChange={(e) => sort(e)}>
                      <option value="1">Domyślne</option>
                      <option value="2">Pojemność rosnąco</option>
                      <option value="3">Pojemność malejąco</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
