import menubox_nd from "../images/pojemniki_styropianowe/menubox_nd.png";
import menubox_2d from "../images/pojemniki_styropianowe/menubox_2d.jpg";
import menubox_3d from "../images/pojemniki_styropianowe/menubox_3d.jpg";
import menubox_pol_obiadu from "../images/pojemniki_styropianowe/menubox_pol_obiadu.jpg";
import hamburger_duzy from "../images/pojemniki_styropianowe/hamburger_duzy.jpg";
import hamburger_podwojny from "../images/pojemniki_styropianowe/hamburger_podwojny.jpg";
import huhtamaki_nd from "../images/pojemniki_styropianowe/huhtamaki_nd.jpg";
import huhtamaki_2d from "../images/pojemniki_styropianowe/huhtamaki_2d.jpg";
import huhtamaki_3d from "../images/pojemniki_styropianowe/huhtamaki_3d.jpg";
import chinet_nd from "../images/pojemniki_styropianowe/chinet_nd.jpg";
import chinet_2d from "../images/pojemniki_styropianowe/chinet_2d.jpg";
import chinet_3d from "../images/pojemniki_styropianowe/chinet_3d.jpg";
import chinet_pol_obiadu from "../images/pojemniki_styropianowe/chinet_pol_obiadu.jpg";
import chinet_hamburger from "../images/pojemniki_styropianowe/chinet_hamburger.jpg";
import chinet_hamburger_podwojny from "../images/pojemniki_styropianowe/chinet_hamburger_podwojny.jpg";

const products = [
  {
    id: 1,
    name: "Menubox niedzielony",
    length: 230,
    width: 190,
    height: 70,
    number_of_compartments: 1,
    color: "Biały",
    amount: 125,
    material: "Styropian",
    image: menubox_nd,
  },
  {
    id: 2,
    name: "Menubox dwudzielny",
    length: 230,
    width: 190,
    height: 70,
    number_of_compartments: 2,
    color: "Biały",
    amount: 125,
    material: "Styropian",
    image: menubox_2d,
  },
  {
    id: 3,
    name: "Menubox trójdzielny",
    length: 230,
    width: 190,
    height: 70,
    number_of_compartments: 3,
    color: "Biały",
    amount: 125,
    material: "Styropian",
    image: menubox_3d,
  },
  {
    id: 4,
    name: "Menubox pół obiadu",
    length: 240,
    width: 135,
    height: 60,
    number_of_compartments: 1,
    color: "Biały",
    amount: 125,
    material: "Styropian",
    image: menubox_pol_obiadu,
  },
  {
    id: 5,
    name: "Hamburger duży",
    length: 155,
    width: 140,
    height: 80,
    number_of_compartments: 1,
    color: "Biały",
    amount: 125,
    material: "Styropian",
    image: hamburger_duzy,
  },
  {
    id: 6,
    name: "Hamburger podwójny",
    length: 180,
    width: 135,
    height: 65,
    number_of_compartments: 1,
    color: "Biały",
    amount: 125,
    material: "Styropian",
    image: hamburger_podwojny,
  },
  {
    id: 7,
    name: "Menubox niedzielony huhtamaki",
    length: 240,
    width: 205,
    height: 75,
    number_of_compartments: 1,
    color: "Biały",
    amount: 160,
    material: "Styropian",
    image: huhtamaki_nd,
  },
  {
    id: 8,
    name: "Menubox dwudzielny huhtamaki",
    length: 240,
    width: 205,
    height: 75,
    number_of_compartments: 2,
    color: "Biały",
    amount: 160,
    material: "Styropian",
    image: huhtamaki_2d,
  },
  {
    id: 9,
    name: "Menubox trójdzielny huhtamaki",
    length: 240,
    width: 205,
    height: 75,
    number_of_compartments: 3,
    color: "Biały",
    amount: 160,
    material: "Styropian",
    image: huhtamaki_3d,
  },
  {
    id: 10,
    name: "Menubox niedzielony chinet",
    length: 230,
    width: 205,
    height: 66,
    number_of_compartments: 1,
    color: "Biały",
    amount: 50,
    material: "Trzcina cukrowa",
    image: chinet_nd,
  },
  {
    id: 11,
    name: "Menubox dwudzielny chinet",
    length: 230,
    width: 205,
    height: 66,
    number_of_compartments: 2,
    color: "Biały",
    amount: 50,
    material: "Trzcina cukrowa",
    image: chinet_2d,
  },
  {
    id: 12,
    name: "Menubox trójdzielny chinet",
    length: 230,
    width: 205,
    height: 66,
    number_of_compartments: 3,
    color: "Biały",
    amount: 50,
    material: "Trzcina cukrowa",
    image: chinet_3d,
  },
  {
    id: 13,
    name: "Menubox pół obiadu chinet",
    length: 230,
    width: 150,
    height: 66,
    number_of_compartments: 1,
    color: "Biały",
    amount: 50,
    material: "Trzcina cukrowa",
    image: chinet_pol_obiadu,
  },
  {
    id: 14,
    name: "Menubox hamburger duży chinet",
    length: 152,
    width: 152,
    height: 45,
    number_of_compartments: 1,
    color: "Biały",
    amount: 50,
    material: "Trzcina cukrowa",
    image: chinet_hamburger,
  },
  {
    id: 15,
    name: "Menubox hamburger podwójny 600ml chinet",
    length: 180,
    width: 125,
    height: 45,
    number_of_compartments: 1,
    color: "Biały",
    amount: 50,
    material: "Trzcina cukrowa",
    image: chinet_hamburger_podwojny,
  },
];

export { products };
