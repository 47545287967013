import React from 'react';
import { Link, Navigate, Redirect } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

export default function SingleProduct({ link_to_products, image_of_product, description }) {
    return (
        <div className="single_product img">
            <Nav.Link as={Link} to={link_to_products}>
                <div className="row zzz">
                    <img class='.img-thumbnail' src={image_of_product} alt={description} />
                </div>
            </Nav.Link>
            <hr style={{ border: "1px solid #0275d8" }} />
            <Nav.Link as={Link} to={link_to_products} className="subnav_link">
                <div className="row justify-content-center text-center contact-info">
                    {description}
                </div>
            </Nav.Link>
        </div>
    )
}