import zloty_krazek from "../images/podklady_pod_tort/zloty_krazek.jpeg";
import zloty_krazek_10 from "../images/podklady_pod_tort/zloty_krazek_10.jpg";
import serwetka_dziergana from "../images/podklady_pod_tort/serwetka_dziergana.jpg";
import zloty_podklad from "../images/podklady_pod_tort/zloty_podklad.jpeg";
import serwetka_prostokatna from "../images/podklady_pod_tort/serwetka_prostokatna.jpg";

const products = [
  {
    id: 1,
    name: "Złoty krążek ∅10",
    diameter: "∅10",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 200,
    type: "Podkład",
    image: zloty_krazek_10,
  },
  {
    id: 2,
    name: "Złoty krążek ∅16",
    diameter: "∅16",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 3,
    name: "Złoty krążek ∅18",
    diameter: "∅18",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 4,
    name: "Złoty krążek ∅20",
    diameter: "∅20",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 5,
    name: "Złoty krążek ∅22",
    diameter: "∅22",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 6,
    name: "Złoty krążek ∅24",
    diameter: "∅24",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 7,
    name: "Złoty krążek ∅26",
    diameter: "∅26",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 8,
    name: "Złoty krążek ∅28",
    diameter: "∅28",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 9,
    name: "Złoty krążek ∅30",
    diameter: "∅30",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 10,
    name: "Złoty krążek ∅32",
    diameter: "∅17",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 11,
    name: "Złoty krążek ∅32 gruby",
    diameter: "∅32",
    shape: "Okrągły",
    material: "Papier",
    color: "Złoty",
    amount: 25,
    type: "Podkład",
    image: zloty_krazek,
  },
  {
    id: 12,
    name: "Serwetka tłuszczoodporna ∅10",
    diameter: "∅10",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 13,
    name: "Serwetka tłuszczoodporna ∅19",
    diameter: "∅19",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 14,
    name: "Serwetka tłuszczoodporna ∅21",
    diameter: "∅21",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 15,
    name: "Serwetka tłuszczoodporna ∅23",
    diameter: "∅23",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 16,
    name: "Serwetka tłuszczoodporna ∅26",
    diameter: "∅26",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 17,
    name: "Serwetka tłuszczoodporna ∅28",
    diameter: "∅28",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 18,
    name: "Serwetka tłuszczoodporna ∅30",
    diameter: "∅30",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 19,
    name: "Serwetka tłuszczoodporna ∅32",
    diameter: "∅32",
    shape: "Okrągły",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_dziergana,
  },
  {
    id: 20,
    name: "Złoty podkład 20x30",
    diameter: "20x30",
    shape: "Prostokątny",
    material: "Papier",
    color: "Złoty",
    amount: 100,
    type: "Podkład",
    image: zloty_podklad,
  },
  {
    id: 21,
    name: "Złoty podkład 30x40",
    diameter: "30x40",
    shape: "Prostokątny",
    material: "Papier",
    color: "Złoty",
    amount: 25,
    type: "Podkład",
    image: zloty_podklad,
  },
  {
    id: 22,
    name: "Złoty podkład 36x46",
    diameter: "36x46",
    shape: "Prostokątny",
    material: "Papier",
    color: "Złoty",
    amount: 25,
    type: "Podkład",
    image: zloty_podklad,
  },
  {
    id: 23,
    name: "Serwetka tłuszczoodporna 25x17",
    diameter: "25x17",
    shape: "Prostokątny",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_prostokatna,
  },
  {
    id: 24,
    name: "Serwetka tłuszczoodporna 32x17",
    diameter: "32x17",
    shape: "Prostokątny",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_prostokatna,
  },
  {
    id: 25,
    name: "Serwetka tłuszczoodporna 32x25",
    diameter: "32x25",
    shape: "Prostokątny",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_prostokatna,
  },
  {
    id: 26,
    name: "Serwetka tłuszczoodporna 38x25",
    diameter: "38x25",
    shape: "Prostokątny",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_prostokatna,
  },
  {
    id: 27,
    name: "Serwetka tłuszczoodporna 38x32",
    diameter: "38x32",
    shape: "Prostokątny",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_prostokatna,
  },
  {
    id: 28,
    name: "Serwetka tłuszczoodporna 45x32",
    diameter: "45x32",
    shape: "Prostokątny",
    material: "PP",
    color: "Biały",
    amount: 250,
    type: "Serwetka",
    image: serwetka_prostokatna,
  },
];

export { products };
