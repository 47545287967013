import pojemnik_nd from "../images/pojemniki_do_zgrzewu/pojemnik_nd.jpg";
import pojemnik_2d from "../images/pojemniki_do_zgrzewu/pojemnik_2d.jpg";
import pojemnik_3d from "../images/pojemniki_do_zgrzewu/pojemnik_3d.jpg";
import pojemnik_colt from "../images/pojemniki_do_zgrzewu/pojemnik_colt.jpg";
import W1_601D from "../images/pojemniki_do_zgrzewu/W1_601D.jpg";
import W1_602D from "../images/pojemniki_do_zgrzewu/W1_602D.jpg";
import W1_603D from "../images/pojemniki_do_zgrzewu/W1_603D.jpg";
import map_nd from "../images/pojemniki_do_zgrzewu/map_nd.jpg";
import W1_623 from "../images/pojemniki_do_zgrzewu/W1_623.jpg";
import przykrywka_pod_zgrzew from "../images/pojemniki_do_zgrzewu/przykrywka_pod_zgrzewu.jpg";
import folia_pod_zgrzew from "../images/pojemniki_do_zgrzewu/folia_pod_zgrzew.jpeg";
import msp_0d from "../images/pojemniki_do_zgrzewu/msp_0d.jpg";
import msp_2d from "../images/pojemniki_do_zgrzewu/msp_2d.jpg";
import msp_3d from "../images/pojemniki_do_zgrzewu/msp_3d.jpg";
import msp_pk from "../images/pojemniki_do_zgrzewu/msp_pk.jpg";

const products = [
  {
    id: 1,
    name: "Pojemnik 227/178/50 pod zgrzew czarny 1-komorowy",
    length: 227,
    width: 178,
    height: 50,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_nd,
  },
  {
    id: 2,
    name: "Pojemnik 227/178/50 pod zgrzew czarny 2-komorowy",
    length: 227,
    width: 178,
    height: 50,
    material: "PP",
    number_of_compartments: 2,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_2d,
  },
  {
    id: 3,
    name: "Pojemnik 227/178/50 pod zgrzew czarny 3-komorowy",
    length: 227,
    width: 178,
    height: 50,
    material: "PP",
    number_of_compartments: 3,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_3d,
  },
  {
    id: 4,
    name: "Pojemnik 227/178/50 pod zgrzew czarny COLT",
    length: 227,
    width: 178,
    height: 50,
    material: "PP",
    number_of_compartments: 2,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_colt,
  },
  {
    id: 5,
    name: "Pojemnik 227/178/40 pod zgrzew czarny 1-komorowy",
    length: 227,
    width: 178,
    height: 40,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_nd,
  },
  {
    id: 6,
    name: "Pojemnik 227/178/40 pod zgrzew czarny 2-komorowy",
    length: 227,
    width: 178,
    height: 40,
    material: "PP",
    number_of_compartments: 2,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_2d,
  },
  {
    id: 7,
    name: "Pojemnik 227/178/40 pod zgrzew czarny 3-komorowy",
    length: 227,
    width: 178,
    height: 40,
    material: "PP",
    number_of_compartments: 3,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_3d,
  },
  {
    id: 8,
    name: "Pojemnik 227/178/30 pod zgrzew czarny 1-komorowy",
    length: 227,
    width: 178,
    height: 30,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_nd,
  },
  {
    id: 9,
    name: "Pojemnik 227/178/30 pod zgrzew czarny 2-komorowy",
    length: 227,
    width: 178,
    height: 30,
    material: "PP",
    number_of_compartments: 2,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_2d,
  },
  {
    id: 10,
    name: "Pojemnik 227/178/30 pod zgrzew czarny 3-komorowy",
    length: 227,
    width: 178,
    height: 30,
    material: "PP",
    number_of_compartments: 3,
    color: "Czarny",
    amount: 50,
    capacity: null,
    type: "pojemnik",
    image: pojemnik_3d,
  },
  {
    id: 11,
    name: "Przykrywka do pojemnika pod zgrzew 227x178x20",
    length: 227,
    width: 178,
    height: 20,
    type: "przykrywka",
    color: "Bezbarwny",
    amount: 50,
    box: "227x178x50/40/30",
    image: przykrywka_pod_zgrzew,
  },
  {
    id: 12,
    name: "Pojemnik W1/601D 650ml",
    length: 187,
    width: 137,
    height: 36,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 640,
    capacity: 650,
    type: "pojemnik",
    image: W1_601D,
  },
  {
    id: 13,
    name: "Pojemnik W1/602D 850ml",
    length: 187,
    width: 137,
    height: 50,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 640,
    capacity: 850,
    type: "pojemnik",
    image: W1_602D,
  },
  {
    id: 14,
    name: "Pojemnik W1/603D 1000ml",
    length: 187,
    width: 137,
    height: 60,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 640,
    capacity: 1000,
    type: "pojemnik",
    image: W1_603D,
  },
  {
    id: 15,
    name: "Przykrywka W2/601 do pojemnika pod zgrzew",
    length: 187,
    width: 137,
    height: 10,
    type: "przykrywka",
    color: "Bezbarwny",
    amount: 640,
    box: "W1/601D, W1/602D, W1/603D",
    image: przykrywka_pod_zgrzew,
  },
  {
    id: 16,
    name: "Pojemnik MAP 178/113/40 500ml",
    length: 178,
    width: 113,
    height: 40,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 320,
    capacity: 500,
    type: "pojemnik",
    image: map_nd,
  },
  {
    id: 17,
    name: "Pojemnik MAP 178/113/50 600ml",
    length: 178,
    width: 113,
    height: 50,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 320,
    capacity: 600,
    type: "pojemnik",
    image: map_nd,
  },
  {
    id: 18,
    name: "Pojemnik MAP 178/113/60 750ml",
    length: 178,
    width: 113,
    height: 60,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 320,
    capacity: 750,
    type: "pojemnik",
    image: map_nd,
  },
  {
    id: 19,
    name: "Pojemnik MAP 178/113/70 850ml",
    length: 178,
    width: 113,
    height: 70,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 320,
    capacity: 850,
    type: "pojemnik",
    image: map_nd,
  },
  // {
  //     "id": 18,
  //     "name": "Pojemnik MAP 227/178/45 1-komorowy",
  //     "length": 227,
  //     "width": 178,
  //     "height": 45,
  //     "material": "PP",
  //     "number_of_compartments": 1,
  //     "color": "Czarny",
  //     "amount": 320,
  //     "capacity": null,
  //     "image": pojemnik_nd
  // },
  // {
  //     "id": 19,
  //     "name": "Pojemnik MAP 227/178/45 3-komorowy",
  //     "length": 227,
  //     "width": 178,
  //     "height": 45,
  //     "material": "PP",
  //     "number_of_compartments": 3,
  //     "color": "Czarny",
  //     "amount": 320,
  //     "capacity": null,
  //     "image": pojemnik_3d
  // },
  // {
  //     "id": 20,
  //     "name": "Pojemnik MAP 227/178/45 COLT",
  //     "length": 227,
  //     "width": 178,
  //     "height": 45,
  //     "material": "PP",
  //     "number_of_compartments": 2,
  //     "color": "Czarny",
  //     "amount": 320,
  //     "capacity": null,
  //     "image": pojemnik_colt
  // },
  {
    id: 23,
    name: "Pojemnik W1/623 2300ml",
    length: 227,
    width: 178,
    height: 81,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 320,
    capacity: 2300,
    type: "pojemnik",
    image: W1_623,
  },
  {
    id: 24,
    name: "Pojemnik W1/622 1750ml",
    length: 227,
    width: 178,
    height: 60,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 320,
    capacity: 1750,
    type: "pojemnik",
    image: W1_623,
  },
  {
    id: 25,
    name: "Pojemnik W1/624 3000ml",
    length: 227,
    width: 178,
    height: 100,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 380,
    capacity: 3000,
    type: "pojemnik",
    image: W1_623,
  },
  {
    id: 26,
    name: "Folia PET/PP pod zgrzew lintop 250 mb 185x250",
    length: 250,
    width: 185,
    thickness: 50,
    type: "Folia",
    color: "Bezbarwny",
    amount: 1,
    image: folia_pod_zgrzew,
  },
  {
    id: 27,
    name: "Folia PET/PP pod zgrzew 250 mb 460x250",
    length: 250,
    width: 460,
    thickness: 50,
    type: "Folia",
    color: "Bezbarwny",
    amount: 1,
    image: folia_pod_zgrzew,
  },
  {
    id: 28,
    name: "Folia PP pod zgrzew 190/50my",
    length: 250,
    width: 190,
    thickness: 50,
    type: "Folia",
    color: "Bezbarwny",
    amount: 1,
    image: folia_pod_zgrzew,
  },
  {
    id: 29,
    name: "Pojemnik żebrowany MSP 1-komorowy",
    length: 227,
    width: 178,
    height: 50,
    material: "PP",
    number_of_compartments: 1,
    color: "Czarny",
    amount: 40,
    capacity: 1100,
    type: "pojemnik",
    image: msp_0d,
  },
  {
    id: 30,
    name: "Pojemnik żebrowany MSP 2-komorowy",
    length: 227,
    width: 178,
    height: 50,
    material: "PP",
    number_of_compartments: 2,
    color: "Czarny",
    amount: 40,
    capacity: 1100,
    type: "pojemnik",
    image: msp_2d,
  },
  {
    id: 31,
    name: "Pojemnik żebrowany MSP 3-komorowy",
    length: 227,
    width: 178,
    height: 50,
    material: "PP",
    number_of_compartments: 3,
    color: "Czarny",
    amount: 40,
    capacity: 1100,
    type: "pojemnik",
    image: msp_3d,
  },
  {
    id: 32,
    name: "Przykrywka wypukła do pojemnika MSP",
    length: 227,
    width: 178,
    height: 230,
    type: "przykrywka",
    color: "Bezbarwny",
    amount: 80,
    box: "MSP",
    image: msp_pk,
  },
];

export { products };
