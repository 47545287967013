import f80 from "../images/pojemniczki/f80.jpg";
import f404 from "../images/pojemniczki/f404.jpg";
import f25 from "../images/pojemniczki/f25.jpg";
import f57 from "../images/pojemniczki/f57.jpg";
import f18 from "../images/pojemniczki/f18.jpg";
import f40 from "../images/pojemniczki/f40.jpg";

const products = [
  {
    id: 1,
    name: "Pojemnik F-80",
    length: 230,
    width: 160,
    height: 97,
    color: "Bezbarwny",
    amount: 300,
    image: f80,
  },
  {
    id: 2,
    name: "Pojemnik F-404",
    length: 200,
    width: 147,
    height: 85,
    color: "Bezbarwny",
    amount: 300,
    image: f404,
  },
  {
    id: 3,
    name: "Pojemnik F-25",
    length: 156,
    width: 164,
    height: 79,
    color: "Bezbarwny",
    amount: 500,
    image: f25,
  },
  {
    id: 4,
    name: "Pojemnik F-57",
    length: 240,
    width: 170,
    height: 57,
    color: "Bezbarwny",
    amount: 300,
    image: f57,
  },
  {
    id: 5,
    name: "Pojemnik F-18",
    length: 195,
    width: 90,
    height: 54,
    color: "Bezbarwny",
    amount: 300,
    image: f18,
  },
  {
    id: 6,
    name: "Pojemnik F-40",
    length: 240,
    width: 171,
    height: 66,
    color: "Bezbarwny",
    amount: 300,
    image: f40,
  },
];

export { products };
