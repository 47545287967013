import pizza from "../images/pizza/pizza.jpg";

const products = [
  {
    id: 1,
    name: "Karton na pizze 26 cm",
    length: 26,
    width: 26,
    height: 5,
    color: "Biały",
    amount: 100,
    type: "karton",
    image: pizza,
  },
  {
    id: 2,
    name: "Karton na pizze 28 cm",
    length: 28,
    width: 28,
    height: 5,
    color: "Biały",
    amount: 100,
    type: "karton",
    image: pizza,
  },
  {
    id: 3,
    name: "Karton na pizze 31 cm",
    length: 31,
    width: 31,
    height: 5,
    color: "Biały",
    amount: 100,
    type: "karton",
    image: pizza,
  },
  {
    id: 4,
    name: "Karton na pizze 32 cm",
    length: 32,
    width: 32,
    height: 5,
    color: "Biały",
    amount: 100,
    type: "karton",
    image: pizza,
  },
  {
    id: 5,
    name: "Karton na pizze 36 cm",
    length: 36,
    width: 36,
    height: 5,
    color: "Biały",
    amount: 100,
    type: "karton",
    image: pizza,
  },
  {
    id: 6,
    name: "Karton na pizze 41 cm",
    length: 41,
    width: 41,
    height: 5,
    color: "Biały",
    amount: 50,
    type: "karton",
    image: pizza,
  },
  {
    id: 7,
    name: "Karton na pizze 45 cm",
    length: 45,
    width: 45,
    height: 5,
    color: "Biały",
    amount: 50,
    type: "karton",
    image: pizza,
  },
  {
    id: 8,
    name: "Karton na pizze 50 cm",
    length: 50,
    width: 50,
    height: 5,
    color: "Biały",
    amount: 50,
    type: "karton",
    image: pizza,
  },
  {
    id: 9,
    name: "Karton na pizze 60 cm",
    length: 60,
    width: 60,
    height: 5,
    color: "Biały",
    amount: 50,
    type: "karton",
    image: pizza,
  },
];

export { products };
