import matic from "../images/reczniki/matic.jpg";
import maxi_celuloza from "../images/reczniki/maxi_celuloza.jpg";
import maxi_makulatura from "../images/reczniki/maxi_makulatura.jpg";
import maxi_zielony from "../images/reczniki/maxi_zielony.jpg";
import mini_celuloza from "../images/reczniki/mini_celuloza.jpg";
import mini_makulatura from "../images/reczniki/mini_makulatura.jpg";
import czysciwo_celuloza from "../images/reczniki/czysciwo_celuloza.jpg";
import czysciwo_makulatura from "../images/reczniki/czysciwo_makulatura.jpg";
import czysciwo_zielone from "../images/reczniki/czysciwo_zielone.jpg";
import foxy from "../images/reczniki/foxy.jpg";
import pt_hipek from "../images/reczniki/pt_hipek.jpg";
import pt_joker from "../images/reczniki/pt_joker.jpg";
import pt_wepa from "../images/reczniki/pt_wepa.jpg";
import pt_jumbo_celuloza from "../images/reczniki/pt_jumbo_celuloza.jpg";
import pt_jumbo_makulatura from "../images/reczniki/pt_jumbo_makulatura.jpg";
import pt_jumbo_szary from "../images/reczniki/pt_jumbo_szary.jpg";
import zz_bialy from "../images/reczniki/zz_bialy.jpg";
import zz_szary from "../images/reczniki/zz_szary.jpg";
import zz_zielony from "../images/reczniki/zz_zielony.jpg";
import czysciwo_bawelniane_kolorowe from "../images/reczniki/czysciwo_bawelniane_kolorowe.jpg";
import czysciwo_bawelniane_biale from "../images/reczniki/czysciwo_bawelniane_biale.jpg";

const products = [
  {
    id: 0,
    name: "Ręcznik papierowy maxi biała celuloza",
    size: "100m",
    type: "recznik",
    image: maxi_celuloza,
  },
  {
    id: 1,
    name: "Ręcznik papierowy maxi biała makulatura",
    size: "100m",
    type: "recznik",
    image: maxi_makulatura,
  },
  {
    id: 2,
    name: "Ręcznik papierowy maxi zielony",
    size: "100m",
    type: "recznik",
    image: maxi_zielony,
  },
  {
    id: 3,
    name: "Ręcznik papierowy MATIC",
    size: "-",
    type: "recznik",
    image: matic,
  },
  {
    id: 4,
    name: "Ręcznik papierowy mini biała celuloza",
    size: "50m",
    type: "recznik",
    image: mini_celuloza,
  },
  {
    id: 5,
    name: "Ręcznik papierowy mini biała makulatura",
    size: "-",
    type: "recznik",
    image: mini_makulatura,
  },
  {
    id: 6,
    name: "Czyściwo papierowe 2szt. biała celuloza",
    size: "200m",
    type: "czysciwo",
    image: czysciwo_celuloza,
  },
  {
    id: 7,
    name: "Czyściwo papierowe 2szt. biała makulatura",
    size: "-",
    type: "czysciwo",
    image: czysciwo_makulatura,
  },
  {
    id: 8,
    name: "Czyściwo papierowe 2szt. zielone",
    size: "-",
    type: "czysciwo",
    image: czysciwo_zielone,
  },
  {
    id: 9,
    name: "Ręcznik kuchenny celulozowy 2szt. Foxy",
    size: "-",
    type: "recznik",
    image: foxy,
  },
  {
    id: 10,
    name: "Papier toaletowy 2-W Joker",
    size: "-",
    type: "pt",
    image: pt_joker,
  },
  {
    id: 11,
    name: "Papier toaletowy 3-W Prestige",
    size: "-",
    type: "pt",
    image: pt_wepa,
  },
  {
    id: 12,
    name: "Papier toaletowy szary Hipek",
    size: "-",
    type: "pt",
    image: pt_hipek,
  },
  {
    id: 13,
    name: "Papier toaletowy jumbo biała celuloza",
    size: "-",
    type: "pt",
    image: pt_jumbo_celuloza,
  },
  {
    id: 14,
    name: "Papier toaletowy jumbo biała makulatura",
    size: "-",
    type: "pt",
    image: pt_jumbo_makulatura,
  },
  {
    id: 15,
    name: "Papier toaletowy jumbo szara makulatura",
    size: "-",
    type: "pt",
    image: pt_jumbo_szary,
  },
  {
    id: 16,
    name: "Ręcznik składany Z-Z biały",
    size: "-",
    type: "recznik",
    image: zz_bialy,
  },
  {
    id: 17,
    name: "Ręcznik składany Z-Z zielony",
    size: "-",
    type: "recznik",
    image: zz_zielony,
  },
  {
    id: 18,
    name: "Ręcznik składany Z-Z szary",
    size: "-",
    type: "recznik",
    image: zz_szary,
  },
  {
    id: 19,
    name: "Czyściwo bawełniane kolorowe 10kg",
    size: "-",
    type: "czysciwo",
    image: czysciwo_bawelniane_kolorowe,
  },
  {
    id: 20,
    name: "Czyściwo bawełniane białe 10kg",
    size: "-",
    type: "czysciwo",
    image: czysciwo_bawelniane_biale,
  },
];

export { products };
