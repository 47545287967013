import zupa_350_n from "../images/zupy/zupa_350_n.jpg";
import zupa_450_n from "../images/zupy/zupa_450_n.jpg";
import zupa_500_n from "../images/zupy/zupa_500_n.jpg";
import zupa_350_c from "../images/zupy/zupa_350_c.jpg";
import zupa_450_c from "../images/zupy/zupa_450_c.jpg";
import zupa_500_c from "../images/zupy/zupa_500_c.jpg";
import zupa_pre from "../images/zupy/zupa_pre.jpg";
import zupa_1000 from "../images/zupy/zupa_1000.jpg";

const products = [
  {
    id: 1,
    name: "Pojemnik na zupę 250ml",
    height: 46,
    diameter: 115,
    capacity: 250,
    material: "PP",
    color: "Bezbarwny",
    amount: 50,
    image: zupa_350_n,
  },
  {
    id: 2,
    name: "Pojemnik na zupę 350ml 8115C",
    height: 56,
    diameter: 115,
    capacity: 350,
    material: "PP",
    color: "Bezbarwny",
    amount: 50,
    image: zupa_350_n,
  },
  {
    id: 3,
    name: "Pojemnik na zupę 450ml 8117C",
    height: 72,
    diameter: 115,
    capacity: 450,
    material: "PP",
    color: "Bezbarwny",
    amount: 50,
    image: zupa_450_n,
  },
  {
    id: 4,
    name: "Pojemnik na zupę 500ml 8118C",
    height: 80,
    diameter: 115,
    capacity: 500,
    material: "PP",
    color: "Bezbarwny",
    amount: 50,
    image: zupa_500_n,
  },
  {
    id: 5,
    name: "Pojemnik na zupę 350ml D-8115C",
    height: 56,
    diameter: 115,
    capacity: 350,
    material: "PP",
    color: "Czarny",
    amount: 50,
    image: zupa_350_c,
  },
  {
    id: 6,
    name: "Pojemnik na zupę 450ml D-8117C",
    height: 72,
    diameter: 115,
    capacity: 450,
    material: "PP",
    color: "Czarny",
    amount: 50,
    image: zupa_450_c,
  },
  {
    id: 7,
    name: "Pojemnik na zupę 500ml D-8118C",
    height: 80,
    diameter: 115,
    capacity: 500,
    material: "PP",
    color: "Czarny",
    amount: 50,
    image: zupa_500_c,
  },
  {
    id: 8,
    name: "Pojemnik na zupę 1000ml",
    height: 160,
    diameter: 125,
    capacity: 1000,
    material: "PP",
    color: "Bezbarwny",
    amount: 50,
    image: zupa_1000,
  },
  {
    id: 9,
    name: "Pojemnik na zupę 350ml PRE",
    height: "-",
    diameter: 125,
    capacity: 350,
    material: "PP",
    color: "Bezbarwny",
    amount: 50,
    image: zupa_pre,
  },
  {
    id: 10,
    name: "Pojemnik na zupę 500ml PRE",
    height: "-",
    diameter: 125,
    capacity: 500,
    material: "PP",
    color: "Bezbarwny",
    amount: 50,
    image: zupa_pre,
  },
];

export { products };
