import pape from "../images/torebki_prozniowe/pape.jpg";

const products = [
  {
    id: 1,
    name: "Torebka do pakowania próżniowego 180x300",
    length: 180,
    width: 300,
    amount: 500,
    image: pape,
  },
  {
    id: 2,
    name: "Torebka do pakowania próżniowego 300x600",
    length: 300,
    width: 600,
    amount: 500,
    image: pape,
  },
  {
    id: 3,
    name: "Torebka do pakowania próżniowego 180x230",
    length: 180,
    width: 230,
    amount: 500,
    image: pape,
  },
  {
    id: 4,
    name: "Torebka do pakowania próżniowego 180x350",
    length: 180,
    width: 350,
    amount: 500,
    image: pape,
  },
  {
    id: 5,
    name: "Torebka do pakowania próżniowego 200x600",
    length: 200,
    width: 600,
    amount: 500,
    image: pape,
  },
  {
    id: 6,
    name: "Torebka do pakowania próżniowego 180x200",
    length: 180,
    width: 200,
    amount: 500,
    image: pape,
  },
  {
    id: 7,
    name: "Torebka do pakowania próżniowego 150x400",
    length: 150,
    width: 400,
    amount: 500,
    image: pape,
  },
  {
    id: 8,
    name: "Torebka do pakowania próżniowego 150x300",
    length: 150,
    width: 300,
    amount: 500,
    image: pape,
  },
  {
    id: 9,
    name: "Torebka do pakowania próżniowego 180x450",
    length: 180,
    width: 450,
    amount: 500,
    image: pape,
  },
  {
    id: 10,
    name: "Torebka do pakowania próżniowego 200x200",
    length: 200,
    width: 200,
    amount: 500,
    image: pape,
  },
  {
    id: 11,
    name: "Torebka do pakowania próżniowego 150x230",
    length: 150,
    width: 230,
    amount: 500,
    image: pape,
  },
  {
    id: 12,
    name: "Torebka do pakowania próżniowego 200x250",
    length: 200,
    width: 250,
    amount: 500,
    image: pape,
  },
  {
    id: 13,
    name: "Torebka do pakowania próżniowego 200x300",
    length: 200,
    width: 300,
    amount: 500,
    image: pape,
  },
  {
    id: 14,
    name: "Torebka do pakowania próżniowego 150x350",
    length: 150,
    width: 350,
    amount: 500,
    image: pape,
  },
  {
    id: 15,
    name: "Torebka do pakowania próżniowego 200x400",
    length: 200,
    width: 400,
    amount: 500,
    image: pape,
  },
  {
    id: 16,
    name: "Torebka do pakowania próżniowego 200x350",
    length: 200,
    width: 350,
    amount: 500,
    image: pape,
  },
  {
    id: 17,
    name: "Torebka do pakowania próżniowego 180x400",
    length: 180,
    width: 400,
    amount: 500,
    image: pape,
  },
  {
    id: 18,
    name: "Torebka do pakowania próżniowego 250x300",
    length: 250,
    width: 300,
    amount: 500,
    image: pape,
  },
  {
    id: 19,
    name: "Torebka do pakowania próżniowego 250x350",
    length: 250,
    width: 350,
    amount: 500,
    image: pape,
  },
  {
    id: 20,
    name: "Torebka do pakowania próżniowego 250x400",
    length: 250,
    width: 400,
    amount: 500,
    image: pape,
  },
  {
    id: 21,
    name: "Torebka do pakowania próżniowego 250x450",
    length: 250,
    width: 450,
    amount: 500,
    image: pape,
  },
  {
    id: 22,
    name: "Torebka do pakowania próżniowego 300x350",
    length: 300,
    width: 350,
    amount: 500,
    image: pape,
  },
  {
    id: 23,
    name: "Torebka do pakowania próżniowego 140x180",
    length: 140,
    width: 180,
    amount: 500,
    image: pape,
  },
  {
    id: 24,
    name: "Torebka do pakowania próżniowego 300x300",
    length: 300,
    width: 300,
    amount: 500,
    image: pape,
  },
  {
    id: 25,
    name: "Torebka do pakowania próżniowego 300x400",
    length: 300,
    width: 400,
    amount: 500,
    image: pape,
  },
  {
    id: 26,
    name: "Torebka do pakowania próżniowego 200x450",
    length: 200,
    width: 450,
    amount: 500,
    image: pape,
  },
  {
    id: 27,
    name: "Torebka do pakowania próżniowego 200x600",
    length: 200,
    width: 600,
    amount: 500,
    image: pape,
  },
  {
    id: 28,
    name: "Torebka do pakowania próżniowego 250x250",
    length: 250,
    width: 250,
    amount: 500,
    image: pape,
  },
  {
    id: 29,
    name: "Torebka do pakowania próżniowego 150x500",
    length: 150,
    width: 500,
    amount: 500,
    image: pape,
  },
  {
    id: 30,
    name: "Torebka do pakowania próżniowego 150x600",
    length: 150,
    width: 600,
    amount: 500,
    image: pape,
  },
  {
    id: 31,
    name: "Torebka do pakowania próżniowego 300x500",
    length: 300,
    width: 500,
    amount: 500,
    image: pape,
  },
  {
    id: 32,
    name: "Torebka do pakowania próżniowego 350x350",
    length: 350,
    width: 350,
    amount: 500,
    image: pape,
  },
  {
    id: 33,
    name: "Torebka do pakowania próżniowego 350x400",
    length: 350,
    width: 400,
    amount: 500,
    image: pape,
  },
  {
    id: 34,
    name: "Torebka do pakowania próżniowego 400x400",
    length: 400,
    width: 400,
    amount: 500,
    image: pape,
  },
  {
    id: 35,
    name: "Torebka do pakowania próżniowego 400x500",
    length: 400,
    width: 500,
    amount: 500,
    image: pape,
  },
  {
    id: 36,
    name: "Torebka do pakowania próżniowego 400x600",
    length: 400,
    width: 600,
    amount: 500,
    image: pape,
  },
  {
    id: 37,
    name: "Torebka do pakowania próżniowego 350x500",
    length: 350,
    width: 500,
    amount: 500,
    image: pape,
  },
  {
    id: 38,
    name: "Torebka do pakowania próżniowego 350x450",
    length: 350,
    width: 450,
    amount: 500,
    image: pape,
  },
];

export { products };
