import sus1 from "../images/pudelka_sus/sus1.png";
import sus2 from "../images/pudelka_sus/sus2.jpg";
import sus3 from "../images/pudelka_sus/sus3.jpg";
import sus4 from "../images/pudelka_sus/sus4.jpg";

const products = [
  {
    id: 1,
    name: "Pudełko z okienkiem na sushi 190x130x50",
    length: 190,
    width: 130,
    height: 50,
    color: "Brązowy",
    amount: 50,
    image: sus1,
  },
  {
    id: 2,
    name: "Pudełko z okienkiem na sushi 220x90x50",
    length: 220,
    width: 90,
    height: 50,
    color: "Brązowy",
    amount: 50,
    image: sus2,
  },
  {
    id: 3,
    name: "Pudełko z okienkiem na sushi 260x190x50",
    length: 260,
    width: 190,
    height: 50,
    color: "Brązowy",
    amount: 50,
    image: sus3,
  },
  {
    id: 4,
    name: "Pudełko z okienkiem na sushi 320x320x50",
    length: 320,
    width: 320,
    height: 50,
    color: "Brązowy",
    amount: 50,
    image: sus4,
  },
];

export { products };
