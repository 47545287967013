import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products } from "../products/reklamowki_data";

export default function ReklamowkiFoliowe() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr style={{ border: "1px solid #0275d8" }} />
          <Card.Text>
            <b>Szerokość:</b> {product.width} cm
            <br />
            <b>Wysokość:</b> {product.height} cm
            <br />
            <b>Pakowane po:</b> {product.amount}
          </Card.Text>

          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  const sort = (e) => {
    const option = document.getElementById("select_sort").value;

    console.log("moja opcja" + option);
    var sorted_products;

    if (option === "2") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.width >= p2.width ? 1 : -1
      );
    } else if (option === "3") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.width >= p2.width ? -1 : 1
      );
    } else if (option === "6") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.height >= p2.height ? 1 : -1
      );
    } else if (option === "7") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.height >= p2.height ? -1 : 1
      );
    } else if (option === "1") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.id >= p2.id ? 1 : -1
      );
    }

    console.log(sorted_products);

    set_list_of_products(map_products(sorted_products));
  };

  const filter = (e) => {
    const min_width = document
      .getElementById("form_min_width")
      .value.toUpperCase();
    const max_width = document
      .getElementById("form_max_width")
      .value.toUpperCase();
    const min_height = document
      .getElementById("form_min_height")
      .value.toUpperCase();
    const max_height = document
      .getElementById("form_max_height")
      .value.toUpperCase();

    var p = products.filter(
      (product) =>
        (min_height === "" || product.height >= min_height) &&
        (max_height === "" || product.height <= max_height) &&
        (min_width === "" || product.width >= min_width) &&
        (max_width === "" || product.width <= max_width)
    );

    set_filtered_products(p);
    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>Reklamówki foliowe - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Reklamówki foliowe do pakowania zakupów."
        />
        <link rel="canonical" href="/reklamowki-foliowe" />
      </Helmet>

      <ProductHeader product_name="Reklamówki foliowe"></ProductHeader>

      <div className="row my-accodrion-row">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filtruj produkty</Accordion.Header>
            <Accordion.Body style={{ "margin-top": 20 }}>
              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Szerokość min.</InputGroup.Text>
                    <Form.Control id="form_min_width" aria-label="min_width" />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Wysokość min.</InputGroup.Text>
                    <Form.Control
                      id="form_min_height"
                      aria-label="min_height"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Sortowanie</InputGroup.Text>
                    <Form.Select id="select_sort" onChange={(e) => sort(e)}>
                      <option value="1">Domyślne</option>
                      <option value="2">Szerokość rosnąco</option>
                      <option value="3">Szerokość malejąco</option>
                      <option value="6">Wysokość rosnąco</option>
                      <option value="7">Wysokość malejąco</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Szerokość max.</InputGroup.Text>
                    <Form.Control id="form_max_width" aria-label="max_width" />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Wysokość max.</InputGroup.Text>
                    <Form.Control
                      id="form_max_height"
                      aria-label="max_height"
                    />
                  </InputGroup>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
