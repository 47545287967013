import kubek_papierowy_kraft from "../images/kubki_papierowe/kubek_papierowy_kraft.jpg";
import kubek_papierowy_bialy from "../images/kubki_papierowe/kubek_papierowy_bialy.jpg";

import kubek_papierowy_bialy_dyrektywa from "../images/kubki_papierowe/kubek_papierowy_bialy_dyrektywa.jpg";
import kubek_papierowy_kraft_dyrektywa from "../images/kubki_papierowe/kubek_papierowy_kraft_dyrektywa.jpg";

import kubek_lovenature from "../images/kubki_papierowe/kubek_lovenature.jpg";

import kubek_karbowany from "../images/kubki_papierowe/kubek_karbowany.jpg";
import owijka from "../images/kubki_papierowe/owijka.jpg";
import przykrywka_czarna from "../images/kubki_papierowe/przykrywka_czarna.jpg";
import przykrywka_biala from "../images/kubki_papierowe/przykrywka_biala.jpg";
import przykrywka_trzcina from "../images/kubki_papierowe/przykrywka_trzcina.jpg";

const products = [
  {
    id: 1,
    name: "Kubek papierowy 100ml biały",
    diameter: 60,
    capacity: 100,
    color: "Biały",
    amount: 100,
    plastic_free: "Nie",
    type: "kubeczek",
    image: kubek_papierowy_bialy_dyrektywa,
  },
  {
    id: 2,
    name: "Kubek papierowy 150 biały",
    diameter: 70,
    capacity: 150,
    color: "Biały",
    amount: 100,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_bialy_dyrektywa,
  },
  {
    id: 3,
    name: "Kubek papierowy 180ml biały",
    diameter: 70,
    capacity: 180,
    color: "Biały",
    amount: 100,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_bialy_dyrektywa,
  },
  {
    id: 4,
    name: "Kubek papierowy 180ml biały plastic free",
    diameter: 70,
    capacity: 180,
    color: "Biały",
    amount: 100,
    type: "kubeczek",
    plastic_free: "Tak",
    image: kubek_papierowy_bialy,
  },
  {
    id: 5,
    name: "Kubek papierowy 250ml kraft",
    diameter: 80,
    capacity: 250,
    color: "Brązowy",
    amount: 100,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_kraft_dyrektywa,
  },
  {
    id: 6,
    name: "Kubek papierowy 250ml biały",
    diameter: 80,
    capacity: 250,
    color: "Biały",
    amount: 100,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_bialy_dyrektywa,
  },
  {
    id: 7,
    name: "Kubek papierowy 250ml plastic free",
    diameter: 80,
    capacity: 250,
    color: "Brązowy",
    amount: 100,
    type: "kubeczek",
    plastic_free: "Tak",
    image: kubek_lovenature,
  },
  {
    id: 8,
    name: "Kubek papierowy 300ml kraft",
    diameter: 90,
    capacity: 300,
    color: "Brązowy",
    amount: 100,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_kraft_dyrektywa,
  },
  {
    id: 9,
    name: "Kubek papierowy 300ml biały",
    diameter: 90,
    capacity: 300,
    color: "Biały",
    amount: 50,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_bialy_dyrektywa,
  },
  {
    id: 10,
    name: "Kubek papierowy 300ml plastic free",
    diameter: 90,
    capacity: 300,
    color: "Brązowy",
    amount: 50,
    type: "kubeczek",
    plastic_free: "Tak",
    image: kubek_lovenature,
  },
  {
    id: 11,
    name: "Kubek papierowy 300ml karbowany",
    diameter: 90,
    capacity: 300,
    color: "Brązowy",
    amount: 500,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_karbowany,
  },
  {
    id: 12,
    name: "Kubek papierowy 400ml biały",
    diameter: 90,
    capacity: 400,
    color: "Biały",
    amount: 50,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_bialy_dyrektywa,
  },
  {
    id: 13,
    name: "Kubek papierowy 400ml biały plastic free",
    diameter: 90,
    capacity: 400,
    color: "Biały",
    amount: 50,
    type: "kubeczek",
    plastic_free: "Tak",
    image: kubek_papierowy_bialy,
  },
  {
    id: 14,
    name: "Kubek papierowy 500ml biały",
    diameter: 90,
    capacity: 500,
    color: "Biały",
    amount: 50,
    type: "kubeczek",
    plastic_free: "Nie",
    image: kubek_papierowy_bialy_dyrektywa,
  },
  {
    id: 15,
    name: "Owijka papierowa do kubka 200-250 ml",
    cup: "200 - 250",
    color: "Brązowy",
    amount: 50,
    type: "owijka",
    image: owijka,
  },
  {
    id: 16,
    name: "Owijka papierowa do kubka 300-500 ml",
    cup: "300 - 500",
    color: "Brązowy",
    amount: 50,
    type: "owijka",
    image: owijka,
  },
  {
    id: 17,
    name: "Przykrywka plastikowa biała do kubka ∅70",
    diameter: "70",
    color: "Biały",
    amount: 100,
    type: "przykrywka",
    image: przykrywka_biala,
  },
  {
    id: 18,
    name: "Przykrywka plastikowa czarna do kubka ∅80",
    diameter: "80",
    color: "Czarny",
    amount: 100,
    type: "przykrywka",
    image: przykrywka_czarna,
  },
  {
    id: 19,
    name: "Przykrywka plastikowa biała do kubka ∅80",
    diameter: "80",
    color: "Biały",
    amount: 100,
    type: "przykrywka",
    image: przykrywka_biala,
  },
  {
    id: 20,
    name: "Przykrywka plastikowa czarna do kubka ∅90",
    diameter: "90",
    color: "Czarny",
    amount: 100,
    type: "przykrywka",
    image: przykrywka_czarna,
  },
  {
    id: 21,
    name: "Przykrywka plastikowa biała do kubka ∅90",
    diameter: "90",
    color: "Biały",
    amount: 100,
    type: "przykrywka",
    image: przykrywka_biala,
  },
  {
    id: 22,
    name: "Przykrywka eko z trzciny cukrowej do kubka ∅90",
    diameter: "90",
    color: "Biały",
    amount: 50,
    type: "przykrywka",
    image: przykrywka_trzcina,
  },
];

export { products };
