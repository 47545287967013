import Slides from "./Slides";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Card from "react-bootstrap/Card";
import ProductHeader from "./ProductHeader";

import guillin from "../images/guillin.png";
import guillin2023 from "../images/guillin-2023.jpg";
import nomapack from "../images/nomapack.png";

import nomapack_pdf from "../catalogs/nomapack.pdf";

export default function Katalog() {
  return (
    <div className="col container_column">
      <Helmet>
        <title>Katalogi produktów - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Katalogi produktów dostępnych w naszej ofercie"
        />
        <link rel="canonical" href="/katalog" />
      </Helmet>

      <ProductHeader product_name="Katalogi"></ProductHeader>
      <div className="row justify-content-center text-center">
        <Card style={{ width: "16rem", margin: 20 }}>
          <Button
            variant="image"
            onClick={(e) =>
              window.open(
                "https://mediatheque.groupeguillin.fr/brochure/CatalogueGUILLINpolska2023/mobile/index.html#p=1",
                "_blank"
              )
            }
            style={{ padding: 0 }}
          >
            <Card.Img
              variant="top"
              style={{ marginTop: 10 }}
              src={guillin2023}
              alt="Katalog Guillin"
            />
          </Button>
          <Card.Body>
            <Card.Title>Katalog produktów Guillin 2023</Card.Title>
            {/* <Button variant="primary">Go somewhere</Button> */}
          </Card.Body>
        </Card>

        <Card style={{ width: "16rem", margin: 20 }}>
          <Button
            variant="image"
            onClick={(e) =>
              window.open(
                "https://mediatheque.groupeguillin.fr/brochure/GuillinPolska2020/ecatalog2020/",
                "_blank"
              )
            }
            style={{ padding: 0 }}
          >
            <Card.Img
              variant="top"
              style={{ marginTop: 10 }}
              src={guillin}
              alt="Katalog Guillin"
            />
          </Button>
          <Card.Body>
            <Card.Title>Katalog produktów Guillin 2020</Card.Title>
            {/* <Button variant="primary">Go somewhere</Button> */}
          </Card.Body>
        </Card>

        <Card style={{ width: "16rem", margin: 20 }}>
          <Button
            variant="image"
            onClick={(e) => window.open(nomapack_pdf)}
            style={{ padding: 0 }}
          >
            <Card.Img
              variant="top"
              style={{ marginTop: 10 }}
              src={nomapack}
              alt="Katalog Nomapack"
            />
          </Button>
          <Card.Body>
            <Card.Title>Katalog produktów Nomapack</Card.Title>
            {/* <Button variant="primary">Go somewhere</Button> */}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
