import ajax5l from "../images/chemia_gospodarcza/ajax5l.jpg";
import balsamb5l from "../images/chemia_gospodarcza/balsamb5l.jpg";
import bingo5l from "../images/chemia_gospodarcza/bingo5l.png";
import balsam5l from "../images/chemia_gospodarcza/balsam5l.jpg";
import ludwik5l from "../images/chemia_gospodarcza/ludwik5l.jpg";
import lucek5l from "../images/chemia_gospodarcza/lucek5l.jpg";
import flor5l from "../images/chemia_gospodarcza/flor5l.jpg";
import meglio5l from "../images/chemia_gospodarcza/meglio5l.jpg";
import dix5l from "../images/chemia_gospodarcza/dix5l.jpg";
import rosa5l from "../images/chemia_gospodarcza/rosa5l.jpg";
import attis5l from "../images/chemia_gospodarcza/attis5l.jpg";
import domestos5l from "../images/chemia_gospodarcza/domestos5l.jpg";
import sinlux5l from "../images/chemia_gospodarcza/sinlux5l.jpg";
import proper5l from "../images/chemia_gospodarcza/proper5l.jpg";
import jelen from "../images/chemia_gospodarcza/jelen.jpg";
import clin from "../images/chemia_gospodarcza/clin.jpg";
import ludwik_szyba from "../images/chemia_gospodarcza/ludwik_szyba.jpg";
import palemka from "../images/chemia_gospodarcza/palemka.jpg";
import tytanwc from "../images/chemia_gospodarcza/tytanwc.jpg";
import pikasat from "../images/chemia_gospodarcza/pikasat.jpg";
import grillpol from "../images/chemia_gospodarcza/grillpol.jpg";
import dix_przypalenia from "../images/chemia_gospodarcza/dix_przypalenia.jpg";
import tytan_przypalenia from "../images/chemia_gospodarcza/tytan_przypalenia.jpg";
import sinlux from "../images/chemia_gospodarcza/sinlux.jpg";
import forlux_odkamieniacz from "../images/chemia_gospodarcza/forlux_odkamieniacz.jpg";
import forlux_naczynia from "../images/chemia_gospodarcza/forlux_naczynia.jpg";
import forlux_nablyszczacz from "../images/chemia_gospodarcza/forlux_nablyszczacz.jpg";
import tabletki_solne from "../images/chemia_gospodarcza/tabletki_solne.jpg";

const products = [
  {
    id: 0,
    name: "Płym do mycia podłóg Ajax 5L",
    image: ajax5l,
    type: "podloga",
  },
  {
    id: 1,
    name: "Płyn do mycia podłóg Bingo 5L",
    image: bingo5l,
    type: "podloga",
  },
  {
    id: 2,
    name: "Płyn do mycia podłóg Floor 5L",
    image: flor5l,
    type: "podloga",
  },
  {
    id: 3,
    name: "Płyn do mycia podłóg Mr. Proper 5L",
    image: proper5l,
    type: "podloga",
  },

  {
    id: 4,
    name: "Płyn do mycia naczyń Balsam extra 5L",
    image: balsamb5l,
    type: "naczynia",
  },

  {
    id: 5,
    name: "Płyn do mycia naczyń Balsam 5L",
    image: balsam5l,
    type: "naczynia",
  },
  {
    id: 6,
    name: "Płyn do mycia naczyń Ludwik 5L",
    image: ludwik5l,
    type: "naczynia",
  },
  {
    id: 7,
    name: "Płyn do mycia naczyń Lucek 5L",
    image: lucek5l,
    type: "naczynia",
  },
  {
    id: 8,
    name: "Płyn do mycia naczyń Dix extra 5L",
    image: dix5l,
    type: "naczynia",
  },
  {
    id: 9,
    name: "Mydło do rąk Rosa 5L",
    image: rosa5l,
    type: "mydlo",
  },
  {
    id: 10,
    name: "Mydło do rąk z lanoliną Attis 5L",
    image: attis5l,
    type: "mydlo",
  },
  {
    id: 11,
    name: "Mydło do rąk Biały Jeleń 5L",
    image: jelen,
    type: "mydlo",
  },
  {
    id: 12,
    name: "Płyn do mycia szyb Sin-Lux 5L",
    image: sinlux5l,
    type: "szyby",
  },
  {
    id: 13,
    name: "Płyn do mycia szyb Clin spray",
    image: clin,
    type: "szyby",
  },
  {
    id: 14,
    name: "Płyn do mycia szyb Ludwik spray",
    image: ludwik_szyba,
    type: "szyby",
  },
  {
    id: 15,
    name: "Płyn do WC Domestos 5L",
    image: domestos5l,
    type: "toaleta",
  },
  {
    id: 16,
    name: "Płyn do WC Tytan",
    image: tytanwc,
    type: "toaleta",
  },
  {
    id: 17,
    name: "Płyn do WC Palemka",
    image: palemka,
    type: "toaleta",
  },
  {
    id: 18,
    name: "Płyn do WC Pikasat 1L",
    image: pikasat,
    type: "toaleta",
  },
  {
    id: 19,
    name: "Płyn do przypaleń Grillpol 1L",
    image: grillpol,
    type: "przypalenia",
  },
  {
    id: 20,
    name: "Płyn do przypaleń Dix spray",
    image: dix_przypalenia,
    type: "przypalenia",
  },
  {
    id: 21,
    name: "Płyn do przypaleń Tytan spray",
    image: tytan_przypalenia,
    type: "przypalenia",
  },
  {
    id: 22,
    name: "Odtłuszczacz Meglio 5L",
    image: meglio5l,
    type: "tluszcz",
  },
  {
    id: 23,
    name: "Odtłuszczacz Sin-lux spray",
    image: sinlux,
    type: "tluszcz",
  },
  {
    id: 24,
    name: "Forlux usuwanie osadów mineralnych",
    image: forlux_odkamieniacz,
    type: "profesjonalna",
  },
  {
    id: 25,
    name: "Forlux maszynowe mycie naczyń",
    image: forlux_naczynia,
    type: "profesjonalna",
  },
  {
    id: 26,
    name: "Forlux nabłyszczanie naczyń w zmywarkach",
    image: forlux_nablyszczacz,
    type: "profesjonalna",
  },
  {
    id: 27,
    name: "Tabletki solne 25kg - do uzdatniania wody",
    image: tabletki_solne,
    type: "profesjonalna",
  },
];

export { products };
