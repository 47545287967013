import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

export default function AdvertismentCard({ title, description, user_icon }) {
    return (
        <div className="col text-center">
            <Card>
                <Card.Title style={{ marginBottom: "-5px" }}>{user_icon}<t /> {title}</Card.Title>
                < hr style={{ border: "1px solid #0275d8" }} />
                {description}
            </Card>
        </div >
    )
};