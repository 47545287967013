import wiaderko_okragle from "../images/wiaderka/wiaderko_1l.jpg";
import wiaderko_p_1l from "../images/wiaderka/wiaderko_p_1l.jpg";
import wiaderko_3l from "../images/wiaderka/wiaderko_3l.jpg";

const products = [
  {
    id: 1,
    name: "Wiaderko okrągłe 1L",
    capacity: 1,
    diameter: 120,
    height: 137,
    color: "Bezbarwny",
    plomba: "Tak",
    material: "PP",
    amount: 100,
    shape: "okrągły",
    image: wiaderko_okragle,
  },
  {
    id: 2,
    name: "Wiaderko okrągłe 3L",
    capacity: 3,
    diameter: 191,
    height: 150,
    color: "Bezbarwny",
    plomba: "Tak",
    material: "PP",
    amount: 25,
    shape: "okrągły",
    image: wiaderko_okragle,
  },
  {
    id: 3,
    name: "Wiaderko okrągłe 5L",
    capacity: 5,
    diameter: 210,
    height: 203,
    color: "Bezbarwny",
    plomba: "Tak",
    material: "PP",
    amount: 25,
    shape: "okrągły",
    image: wiaderko_okragle,
  },
  {
    id: 4,
    name: "Wiaderko okrągłe 10L",
    capacity: 10,
    diameter: 276,
    height: 199,
    color: "Bezbarwny",
    plomba: "Tak",
    material: "PP",
    amount: 10,
    shape: "okrągły",
    image: wiaderko_okragle,
  },
  {
    id: 5,
    name: "Wiaderko prostokątne 1L",
    capacity: 1,
    length: 145,
    width: 88,
    height: 101,
    color: "Bezbarwny",
    plomba: "Tak",
    material: "PP",
    amount: 10,
    shape: "prostokątny",
    image: wiaderko_p_1l,
  },
  {
    id: 6,
    name: "Wiaderko prostokątne 3L",
    capacity: 3,
    length: 210,
    width: 128,
    height: 140,
    color: "Bezbarwny",
    plomba: "Tak",
    material: "PP",
    amount: 10,
    shape: "prostokątny",
    image: wiaderko_3l,
  },
];

export { products };
