import salatka_diament from "../images/pojemniki_salatkowe/salatka_diament.jpg";
import salatka_1500 from "../images/pojemniki_salatkowe/salatka_1500.jpg";
import salatka_osmiokat from "../images/pojemniki_salatkowe/salatka_osmiokat.jpg";
import salatka_czarna from "../images/pojemniki_salatkowe/salatka_czarna.jpg";

const products = [
  {
    id: 0,
    name: "Pojemnik sałatkowy 375ml diament",
    capacity: 375,
    diameter: 134,
    material: "PET",
    amount: 50,
    top: "Tak",
    image: salatka_diament,
  },
  {
    id: 1,
    name: "Pojemnik sałatkowy 500ml diament",
    capacity: 500,
    diameter: 154,
    material: "PET",
    amount: 50,
    top: "Tak",
    image: salatka_diament,
  },
  {
    id: 2,
    name: "Pojemnik sałatkowy 7500ml diament",
    capacity: 750,
    diameter: 177,
    material: "PET",
    amount: 50,
    top: "Tak",
    image: salatka_diament,
  },
  {
    id: 3,
    name: "Pojemnik sałatkowy 1000ml diament",
    capacity: 1000,
    diameter: 190,
    material: "PET",
    amount: 50,
    top: "Tak",
    image: salatka_diament,
  },
  {
    id: 4,
    name: "Pojemnik sałatkowy 1500ml",
    capacity: 1500,
    diameter: 208,
    material: "PET",
    amount: 50,
    top: "Tak",
    image: salatka_1500,
  },
  {
    id: 5,
    name: "Pojemnik sałatkowy 375ml ośmiokąty",
    capacity: 375,
    diameter: "-",
    material: "PP",
    amount: 50,
    top: "Tak",
    image: salatka_osmiokat,
  },
  {
    id: 6,
    name: "Pojemnik sałatkowy 500ml ośmiokąty",
    capacity: 500,
    diameter: "-",
    material: "PP",
    amount: 50,
    top: "Tak",
    image: salatka_osmiokat,
  },
  {
    id: 7,
    name: "Pojemnik sałatkowy 750ml ośmiokąty",
    capacity: 750,
    diameter: "-",
    material: "PP",
    amount: 50,
    top: "Tak",
    image: salatka_osmiokat,
  },
  {
    id: 8,
    name: "Pojemnik sałatkowy 1500ml ośmiokąty",
    capacity: 1500,
    diameter: "-",
    material: "PP",
    amount: 50,
    top: "Tak",
    image: salatka_osmiokat,
  },
  {
    id: 9,
    name: "Pojemnik sałatkowy 1000ml czarny",
    capacity: 1000,
    diameter: "180",
    material: "PP",
    amount: 50,
    top: "Nie",
    image: salatka_czarna,
  },
  {
    id: 10,
    name: "Pojemnik sałatkowy 1500ml czarny",
    capacity: 1500,
    diameter: "200",
    material: "PP",
    amount: 50,
    top: "Nie",
    image: salatka_czarna,
  },
  {
    id: 10,
    name: "Pojemnik sałatkowy 2000ml czarny",
    capacity: 2000,
    diameter: "230",
    material: "PP",
    amount: 50,
    top: "Nie",
    image: salatka_czarna,
  },
];

export { products };
