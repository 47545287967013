import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";
import Accordion from "react-bootstrap/Accordion";

import { products } from "../products/rekawiczki_data";

export default function Rekawiczki() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr style={{ border: "1px solid #0275d8" }} />
          <Card.Text>
            <div>
              <b>Rozmiar:</b> {product.size}
              <br />
              <b>Pakowane po:</b> {product.amount}
            </div>
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  return (
    <div className="col container_column">
      <Helmet>
        <title>Rękawiczki - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Rękawiczki nitrylowe, lateksowe, winylowe oraz robocze"
        />
        <link rel="canonical" href="/rekawiczki" />
      </Helmet>

      <ProductHeader product_name="Rękawiczki nitrylowe, lateksowe, winylowe oraz robocze"></ProductHeader>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
