import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products } from "../products/przykrycia_do_zgrzewu_data.js";

export default function TaceKateringowe() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr />
          <Card.Text>
            {product.type === "Folia" && (
              <div>
                <b>Długość:</b> {product.length} mb
                <br />
                <b>Szerokość:</b> {product.width} mm
                <br />
                <b>Grubość:</b> {product.thickness} μm
                <br />
                <b>Kolor:</b> {product.color}
                <br />
              </div>
            )}
            {product.type === "Przykrywka" && (
              <div>
                <b>Długość:</b> {product.length} mm
                <br />
                <b>Szerokość:</b> {product.width} mm
                <br />
                <b>Wysokość:</b> {product.height} mm
                <br />
                <b>Kolor:</b> {product.color}
                <br />
                <b>Pakowane po:</b> {product.amount} sztuk
              </div>
            )}
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  const sort = (e) => {
    const option = document.getElementById("select_sort").value;

    console.log("moja opcja" + option);
    var sorted_products;

    if (option === "2") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.width >= p2.width ? 1 : -1
      );
    } else if (option === "3") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.width >= p2.width ? -1 : 1
      );
    } else if (option === "4") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.length >= p2.length ? 1 : -1
      );
    } else if (option === "5") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.length >= p2.length ? -1 : 1
      );
    } else if (option === "6") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.height >= p2.height ? 1 : -1
      );
    } else if (option === "7") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.height >= p2.height ? -1 : 1
      );
    }
    if (option === "1") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.id >= p2.id ? 1 : -1
      );
    }

    console.log(sorted_products);

    set_list_of_products(map_products(sorted_products));
  };

  const filter = (e) => {
    const type = document.getElementById("select_type").value;

    var p = products.filter((product) => type === "" || product.type === type);

    set_filtered_products(p);
    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>
          Przykrycia do pojemników pod zgrzewu - Hurtownia Opakowań Henryk
          Klimowicz
        </title>
        <meta
          name="description"
          content="Przykrycia do pojemników pod zgrzew. Folie do zgrzewu oraz przykryki plastikowe."
        />
        <link rel="canonical" href="/przykrycia-do-pojemnikow-pod-zgrzew" />
      </Helmet>

      <ProductHeader product_name="Przykrycia do pojemników pod zgrzew"></ProductHeader>

      <div className="row my-accodrion-row">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filtruj produkty</Accordion.Header>
            <Accordion.Body style={{ "margin-top": 20 }}>
              <div className="row">
                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Typ</InputGroup.Text>
                    <Form.Select
                      id="select_type"
                      aria-label="type"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="Przykrywka">Przykrywki</option>
                      <option value="Folia">Folie</option>
                    </Form.Select>
                  </InputGroup>
                </div>

                {/* <div className='col-md-3'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Sortowanie</InputGroup.Text>
                        <Form.Select id="select_sort" onChange={e => sort(e)}>
                            <option value="1">Domyślne</option>
                            <option value="2">Szerokość rosnąco</option>
                            <option value="3">Szerokość malejąco</option>
                            <option value="4">Długość rosnąco</option>
                            <option value="5">Długość malejąco</option>
                            <option value="6">Wysokość rosnąco</option>
                            <option value="7">Wysokość malejąco</option>
                        </Form.Select>
                    </InputGroup>
                </div> */}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
