import karton_klapowy from "../images/kartony_klapowe/karton_klapowy.jpg";
import paczkomat_1 from "../images/kartony_klapowe/karton_paczkomat_30_25_15.jpg";
import paczkomat_2 from "../images/kartony_klapowe/karton_paczkomat_60_30_15.jpg";
import paczkomat_3 from "../images/kartony_klapowe/karton_paczkomat_35_25_8.jpg";

const products = [
  {
    id: 0,
    name: "Karton klapowy 40x20x20cm 3-W",
    width: 40,
    length: 20,
    height: 20,
    amount: 20,
    type: "klapowy",
    image: karton_klapowy,
  },
  {
    id: 1,
    name: "Karton klapowy 49x27x25cm 3-W",
    width: 49,
    length: 27,
    height: 25,
    amount: 20,
    type: "klapowy",
    image: karton_klapowy,
  },
  {
    id: 2,
    name: "Karton klapowy 50x30x30cm 5-W",
    width: 50,
    length: 30,
    height: 30,
    amount: 10,
    type: "klapowy",
    image: karton_klapowy,
  },
  {
    id: 3,
    name: "Karton klapowy 57x45x38cm 3-W",
    width: 57,
    length: 45,
    height: 38,
    amount: 20,
    type: "klapowy",
    image: karton_klapowy,
  },
  {
    id: 4,
    name: "Karton do paczkomatu gabaryt B 30x25x15cm",
    width: 30,
    length: 25,
    height: 15,
    amount: 20,
    type: "paczkomat",
    image: paczkomat_1,
  },
  {
    id: 5,
    name: "Karton do paczkomatu gabaryt B 60x30x15cm",
    width: 60,
    length: 30,
    height: 15,
    amount: 20,
    type: "paczkomat",
    image: paczkomat_2,
  },
  {
    id: 6,
    name: "Karton do paczkomatu gabaryt A 35x25x8cm",
    width: 35,
    length: 25,
    height: 8,
    amount: 20,
    type: "paczkomat",
    image: paczkomat_3,
  },
];

export { products };
