import zolta from "../images/serwetki/zolta.jpg";
import zielona from "../images/serwetki/zielona.jpg";
import biala from "../images/serwetki/biala.jpg";
import szampan from "../images/serwetki/szampan.jpg";
import czerwona from "../images/serwetki/czerwona.jpg";
import czarna from "../images/serwetki/czarna.jpg";
import gastronomiczna from "../images/serwetki/gastronomiczna.jpg";
import fizelina_biala from "../images/serwetki/fizelina_biala.jpg";
import barowa_biala from "../images/serwetki/barowa_biala.jpg";
import barowa_czarna from "../images/serwetki/barowa_czarna.jpg";

const products = [
  {
    id: 0,
    name: "Serwetka 2-warstwowa czerwona",
    size: "33x33",
    layers: 2,
    amount: "250 szt.",
    image: czerwona,
  },
  {
    id: 0,
    name: "Serwetka 2-warstwowa żółta",
    size: "33x33",
    layers: 2,
    amount: "250 szt.",
    image: zolta,
  },
  {
    id: 0,
    name: "Serwetka 2-warstwowa zielona",
    size: "33x33",
    layers: 2,
    amount: "250 szt.",
    image: zielona,
  },
  {
    id: 0,
    name: "Serwetka 2-warstwowa szampan",
    size: "33x33",
    layers: 2,
    amount: "250 szt.",
    image: szampan,
  },
  {
    id: 0,
    name: "Serwetka 2-warstwowa biała",
    size: "33x33",
    layers: 2,
    amount: "250 szt.",
    image: biala,
  },
  {
    id: 0,
    name: "Serwetka 2-warstwowa czarna",
    size: "33x33",
    layers: 2,
    amount: "250 szt.",
    image: czarna,
  },
  {
    id: 0,
    name: "Serwetka gastronomiczna",
    size: "15x15",
    layers: 1,
    amount: "500 szt.",
    image: gastronomiczna,
  },
  {
    id: 0,
    name: "Serwetka fizelinowa biała",
    size: "40x40",
    layers: 1,
    amount: "50 szt.",
    image: fizelina_biala,
  },
  {
    id: 0,
    name: "Serwetka barowa biała",
    size: "24x24",
    layers: 1,
    amount: "200 szt.",
    image: barowa_biala,
  },
  {
    id: 0,
    name: "Serwetka barowa czarna",
    size: "24x24",
    layers: 1,
    amount: "50 szt.",
    image: barowa_czarna,
  },
];

export { products };
