import torba_1 from "../images/torebki_papierowe/faldowa_duza_350_180_60.jpg";
import torba_2 from "../images/torebki_papierowe/faldowa_mala_250_120_50.jpg";
import torba_3 from "../images/torebki_papierowe/bagietka_150_100_55.jpg";
import torba_4 from "../images/torebki_papierowe/bagietka_270_120_50.jpg";
import torba_5 from "../images/torebki_papierowe/bagietka_340_130_70.jpg";
import torba_6 from "../images/torebki_papierowe/bagietka_410_240_80.jpg";
import torba_7 from "../images/torebki_papierowe/bagietka_580_100_60.jpg";
import torba_8 from "../images/torebki_papierowe/okno_330_150_70.jpg";
import torba_9 from "../images/torebki_papierowe/okno_280_150_70.jpg";
import torba_10 from "../images/torebki_papierowe/torba_klockowa_brazowa_320_170_450.jpg";

const products = [
  {
    id: 1,
    name: "Torebka papierowa fałdowa duża 350x180x60",
    width: 60,
    length: 180,
    height: 350,
    color: "Biały",
    amount: 1000,
    window: "F",
    image: torba_1,
  },
  {
    id: 2,
    name: "Torebka papierowa fałdowa mała 250x120x50",
    width: 50,
    length: 120,
    height: 250,
    color: "Biały",
    amount: 1000,
    window: "F",
    image: torba_2,
  },
  {
    id: 3,
    name: "Torba papierowa szara 150x100x55",
    width: 55,
    length: 100,
    height: 150,
    color: "Brązowy",
    amount: 1000,
    window: "F",
    image: torba_3,
  },
  {
    id: 4,
    name: "Torba papierowa szara 270x120x50",
    width: 50,
    length: 120,
    height: 270,
    color: "Brązowy",
    amount: 1000,
    window: "F",
    image: torba_4,
  },
  {
    id: 5,
    name: "Torba papierowa szara 340x130x70",
    width: 70,
    length: 130,
    height: 340,
    color: "Brązowy",
    amount: 1000,
    window: "F",
    image: torba_5,
  },
  {
    id: 6,
    name: "Torba papierowa szara 410x240x80",
    width: 80,
    length: 240,
    height: 410,
    color: "Brązowy",
    amount: 1000,
    window: "F",
    image: torba_6,
  },
  {
    id: 7,
    name: "Torba papierowa bagietka 500x100x60",
    width: 60,
    length: 100,
    height: 580,
    color: "Brązowy",
    amount: 250,
    window: "F",
    image: torba_7,
  },
  {
    id: 8,
    name: "Torba papierowa szara okno 330x150x70",
    width: 70,
    length: 150,
    height: 330,
    color: "Brązowy",
    amount: 1000,
    window: "T",
    image: torba_8,
  },
  {
    id: 9,
    name: "Torba papierowa szara okno 280x150x70",
    width: 70,
    length: 150,
    height: 280,
    color: "Brązowy",
    amount: 1000,
    window: "T",
    image: torba_9,
  },
  {
    id: 10,
    name: "Torba papierowa TKL 250x110x320",
    width: 110,
    length: 250,
    height: 320,
    color: "Brązowy",
    amount: 250,
    window: "N",
    image: torba_10,
  },
  {
    id: 11,
    name: "Torba papierowa szara 340x180x60",
    width: 60,
    length: 180,
    height: 340,
    color: "Brązowy",
    amount: 1000,
    window: "F",
    image: torba_5,
  },
];

export { products };
