import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products } from "../products/torebki_papierowe_data.js";

export default function TorebkiPapierowe() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState(false);
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr style={{ border: "1px solid #0275d8" }} />
          <Card.Text>
            <b>Długość:</b> {product.length} mm
            <br />
            <b>Szerokość:</b> {product.width} mm
            <br />
            <b>Wysokość:</b> {product.height} mm
            <br />
            <b>Kolor:</b> {product.color}
            <br />
            <b>Pakowane po:</b> {product.amount} sztuk
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  const sort = (e) => {
    const option = document.getElementById("select_sort").value;

    console.log("moja opcja" + option);
    var sorted_products;

    if (option === "2") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.width >= p2.width ? 1 : -1
      );
    } else if (option === "3") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.width >= p2.width ? -1 : 1
      );
    } else if (option === "4") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.length >= p2.length ? 1 : -1
      );
    } else if (option === "5") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.length >= p2.length ? -1 : 1
      );
    } else if (option === "6") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.height >= p2.height ? 1 : -1
      );
    } else if (option === "7") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.height >= p2.height ? -1 : 1
      );
    } else if (option === "1") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.id >= p2.id ? 1 : -1
      );
    }

    console.log(sorted_products);

    set_list_of_products(map_products(sorted_products));
  };

  const filter = (e) => {
    const min_width = document
      .getElementById("form_min_width")
      .value.toUpperCase();
    const max_width = document
      .getElementById("form_max_width")
      .value.toUpperCase();
    const min_length = document
      .getElementById("form_min_length")
      .value.toUpperCase();
    const max_length = document
      .getElementById("form_max_length")
      .value.toUpperCase();
    const min_height = document
      .getElementById("form_min_height")
      .value.toUpperCase();
    const max_height = document
      .getElementById("form_max_height")
      .value.toUpperCase();
    const color = document.getElementById("form_color").value;
    const window = document.getElementById("window_select").value;

    var p = products.filter(
      (product) =>
        product.color.includes(color) &&
        product.window.includes(window) &&
        (min_height === "" || product.height >= min_height) &&
        (max_height === "" || product.height <= max_height) &&
        (min_length === "" || product.length >= min_length) &&
        (max_length === "" || product.length <= max_length) &&
        (min_width === "" || product.width >= min_width) &&
        (max_width === "" || product.width <= max_width)
    );

    set_filtered_products(p);
    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>Torebki papierowe - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Przeglądaj torebki papierowe na bagietki dostępne w naszej ofercie. Różne rozmiary oraz kolory."
        />
        <link rel="canonical" href="/torebki-papierowe/" />
      </Helmet>

      <ProductHeader product_name="Torebki papierowe"></ProductHeader>
      <div className="row my-accodrion-row">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filtruj produkty</Accordion.Header>
            <Accordion.Body style={{ "margin-top": 20 }}>
              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Długość min.</InputGroup.Text>
                    <Form.Control
                      id="form_min_length"
                      aria-label="min_length"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Szerokość min.</InputGroup.Text>
                    <Form.Control id="form_min_width" aria-label="min_width" />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Wysokość min.</InputGroup.Text>
                    <Form.Control
                      id="form_min_height"
                      aria-label="min_height"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Kolor</InputGroup.Text>
                    <Form.Select
                      id="form_color"
                      aria-label="color_select"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="Brązowy">Brązowy</option>
                      <option value="Biały">Biały</option>
                    </Form.Select>
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Sortowanie</InputGroup.Text>
                    <Form.Select id="select_sort" onChange={(e) => sort(e)}>
                      <option value="1">Domyślne</option>
                      <option value="2">Szerokość rosnąco</option>
                      <option value="3">Szerokość malejąco</option>
                      <option value="4">Długość rosnąco</option>
                      <option value="5">Długość malejąco</option>
                      <option value="6">Wysokość rosnąco</option>
                      <option value="7">Wysokość malejąco</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Długość max.</InputGroup.Text>
                    <Form.Control
                      id="form_max_length"
                      aria-label="max_length"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Szerokość max.</InputGroup.Text>
                    <Form.Control id="form_max_width" aria-label="max_width" />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Wysokość max.</InputGroup.Text>
                    <Form.Control
                      id="form_max_height"
                      aria-label="max_height"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-2">
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Okno</InputGroup.Text>
                    <Form.Select
                      id="window_select"
                      aria-label="window_select"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="T">Tak</option>
                      <option value="F">Nie</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
