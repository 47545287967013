import torba_1 from "../images/torebki_krzyzowe/torebka_krzyzowa.jpg";
import torba_2 from "../images/torebki_krzyzowe/bulka_tarta.jpg";

const products = [
  {
    id: 1,
    name: "Torebka krzyżowa nr 2",
    width: 12,
    height: 16,
    capacity: 0.25,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 2,
    name: "Torebka krzyżowa nr 3",
    width: 13,
    height: 23,
    capacity: 0.5,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 3,
    name: "Torebka krzyżowa nr 4",
    width: 17,
    height: 21,
    capacity: 0.75,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 4,
    name: "Torebka krzyżowa nr 4 bułka tarta",
    width: 17,
    height: 21,
    capacity: 0.75,
    color: "Nadruk",
    amount: 10,
    image: torba_2,
  },
  {
    id: 5,
    name: "Torebka krzyżowa nr 5",
    width: 15,
    height: 26,
    capacity: 1,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 6,
    name: "Torebka krzyżowa nr 6",
    width: 20,
    height: 24,
    capacity: 1.5,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 7,
    name: "Torebka krzyżowa nr 7",
    width: 20,
    height: 29,
    capacity: 2,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 8,
    name: "Torebka krzyżowa nr 8",
    width: 23,
    height: 37,
    capacity: 3,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
  {
    id: 9,
    name: "Torebka krzyżowa nr 9",
    width: 28,
    height: 37,
    capacity: 5,
    color: "Brązowy",
    amount: 10,
    image: torba_1,
  },
];

export { products };
