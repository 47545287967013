import { Container, Alert } from "react-bootstrap";
import { FiMapPin } from "react-icons/fi";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import contact_style from "../css/contact_style.css";
import { Helmet } from "react-helmet";

export default function Contact() {
  return (
    <div className="col container_column">
      <Helmet>
        <title>Kontakt - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Skontatkuj się z hurtownią za pomocą telefonu lub poprzez wiadomość email. Zobacz jak do nas dojechać."
        />
        <link rel="canonical" href="/kontakt" />
      </Helmet>

      <div className="row justify-content-center align-items-center">
        <div className="row">
          <Alert className="primary text-center contact-info">
            <Alert.Heading>Dane firmy</Alert.Heading>
            <hr />
            <p>Hurtownia Opakowań Henryk Klimowicz</p>
            <p>15-546 Białystok, ul. Wielkopolska 2</p>
            <p>NIP: 542-010-69-66</p>
          </Alert>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="row justify-content-center contact-address-align">
              <div className="col-md-auto">
                <div className="row ">
                  <div className="col-md-auto">
                    <FiMapPin style={{ color: "#0275d8", fontSize: "25px" }} />
                  </div>
                  <div className="col-md-auto contact-info">
                    <b>Adres:</b>
                  </div>
                </div>
              </div>
              <div className="col-md-auto contact-info">
                <div className="row-md-auto">Białystok, ul.Wielkopolska 2</div>
              </div>
            </div>

            <div className="row justify-content-center md-auto contact-align">
              <div className="col-md-auto">
                <div className="row justify-content-end">
                  <div className="col-md-auto">
                    <BiTime style={{ color: "#0275d8", fontSize: "25px" }} />
                  </div>
                  <div className="col-md-auto contact-info">
                    <b>Godziny otwarcia:</b>
                  </div>
                </div>
              </div>
              <div className="col-md-auto contact-info">
                <div className="row-md-auto">
                  poniedziałek - piątek: 7:00 - 17:00
                </div>
                <div className="row-md-auto">sobota: 8:00 - 14:00</div>
                <div className="row-md-auto">niedziela: nieczynne</div>
              </div>
            </div>

            <div className="row justify-content-center contact-align">
              <div className="col-md-auto">
                <div className="row justify-content-end">
                  <div className="col-md-auto">
                    <BsFillTelephoneOutboundFill
                      style={{ color: "#0275d8", fontSize: "25px" }}
                    />
                  </div>
                  <div className="col-md-auto contact-info">
                    <b>Telefon:</b>
                  </div>
                </div>
              </div>
              <div className="col-md-auto contact-info">
                <div className="row-md-auto">85-743-19-32</div>
                <div className="row-md-auto">85-743-10-55</div>
              </div>
            </div>

            <div className="row justify-content-center md-auto contact-align">
              <div className="col-md-auto">
                <div className="row justify-content-end">
                  <div className="col-md-auto">
                    <AiOutlineMail
                      style={{ color: "#0275d8", fontSize: "25px" }}
                    />
                  </div>
                  <div className="col-md-auto contact-info">
                    <b>Email:</b>
                  </div>
                </div>
              </div>
              <div className="col-md-auto contact-info">
                <div className="row-md-auto">opakowania1@wp.pl</div>
                <div className="row-md-auto">opakowania11@wp.pl</div>
              </div>
            </div>
          </div>

          <div className="col-md-6 google-map-col">
            <iframe
              title="Mapa"
              height="100%"
              width="100%"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Bia%C5%82ystok,%20Hurtownia%20Opakowa%C5%84%20Henryk%20Klimowicz&t=&z=15&ie=UTF8&iwloc=&output=embed"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
