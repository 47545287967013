import Slides from "./Slides";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link, Navigate, Redirect } from "react-router-dom";
import { FaTruck, FaMedal } from "react-icons/fa";
import { GiEcology } from "react-icons/gi";
import { Helmet } from "react-helmet";
import AdvertismentCard from "./AdvertismentCard.js";
import pojemnik_nd from "../images/pojemniki_do_zgrzewu/pn.jpg";
import SingleProduct from "./SingleProduct.js";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import kraft_1000 from "../images/pojemniki_kraft/kraft_1000.jpg";
import torba_2 from "../images/torby_papierowe/tp.png";
import stretch from "../images/opakowania_przemyslowe/folia_stretch_bezbarwna.jpg";
import pizza from "../images/pizza/pizza.jpg";

import "../index.css";

export default function Start() {
  return (
    <Container>
      <Helmet>
        <title>Strona główna - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Hurtownia Opakowań zajmująca się sprzedażą opakowań jednorazowych takich jak torby papierowe i pojemniki. Zaopatrujemy punkty gastronomiczne oraz lokale usługowe."
        />
        <link rel="canonical" href="/start" />
      </Helmet>

      <div className="row mt-3">
        <Slides />
      </div>

      <div class="product">
        <img src={stretch} alt="Folia stretch" class="product-image" />
        <div class="product-description">
          <h3>Folia stretch</h3>
          <hr style={{ border: "1px solid #0275d8" }} />
          <p>
            Niech Twoje produkty podróżują w pełnym bezpieczeństwie i zachowują
            swoją doskonałą kondycję dzięki naszej wysokiej jakości folii
            stretch. Nasza folia stretch to niezawodne narzędzie, które sprawia,
            że pakowanie staje się prostsze i bardziej efektywne.
          </p>
          <p>
            Dzięki swojej elastyczności, folia stretch idealnie przylega do
            różnych kształtów i rozmiarów towarów, zapewniając im solidną
            ochronę podczas transportu i przechowywania. Chroni przed
            zarysowaniami, kurzem, wilgocią i innymi czynnikami zewnętrznymi,
            które mogą wpływać na jakość produktów.
          </p>
        </div>
      </div>

      <div class="product">
        <div class="product-description">
          <h3>Pojemniki pod zgrzew</h3>
          <hr style={{ border: "1px solid #0275d8" }} />
          <p>
            Zapewnij swoim klientom najlepszą jakość i świeżość swoich produktów
            dzięki naszym pojemnikom do zgrzewu na żywność. Nasze pojemniki
            zostały zaprojektowane, aby zachować świeżość i aromat Twoich dań,
            zapewniając jednocześnie optymalne bezpieczeństwo.
          </p>
          <p>
            Wykonane z wysokiej jakości materiałów, nasze pojemniki do zgrzewu
            są wytrzymałe i trwałe, zapewniając solidną ochronę przed
            uszkodzeniami mechanicznymi i utratą świeżości. Ich solidna
            konstrukcja sprawia, że są idealne do przechowywania i transportu
            różnych produktów spożywczych, takich jak dania gotowe, przekąski,
            owoce, warzywa i wiele innych.
          </p>
        </div>
        <img
          src={pojemnik_nd}
          alt="Pojemnik pod zgrzew"
          class="product-image"
        />
      </div>

      <div class="product">
        <img src={pizza} alt="Karton na pizze" class="product-image" />
        <div class="product-description">
          <h3>Kartony na pizze</h3>
          <hr style={{ border: "1px solid #0275d8" }} />
          <p>
            Zapewnij swoim klientom niezapomniane doświadczenie i podkreśl swoją
            markę dzięki naszym kartonom na pizzę z możliwością wykonania
            nadruku. Nasze kartony nie tylko doskonale chronią Twoje pyszne
            pizze, ale również stanowią efektywny nośnik reklamowy.
          </p>
          <p>
            Nasze kartony na pizzę są wykonane z wysokiej jakości tektury,
            zapewniając wytrzymałość i ochronę dla Twoich produktów. Bez względu
            na to, czy dostarczasz pizzę na wynos, czy prowadzisz dostawy, nasze
            kartony są idealnym rozwiązaniem do bezpiecznego transportu i
            przechowywania.
          </p>
        </div>
      </div>

      <div className="row square-row">
        <div className="col start-square">
          <AdvertismentCard
            title="Darmowa dostawa"
            description="Zamówione towary dowozimy za darmo"
            user_icon={
              <FaTruck style={{ color: "#0275d8", fontSize: "25px" }} />
            }
          ></AdvertismentCard>
        </div>
        <div className="col start-square">
          <AdvertismentCard
            title="Ekologiczne opakowania"
            description="W ofercie posiadamy produkty ekologiczne"
            user_icon={
              <GiEcology style={{ color: "#0275d8", fontSize: "25px" }} />
            }
          ></AdvertismentCard>
        </div>
        <div className="col start-square">
          <AdvertismentCard
            title="Wysoka jakość"
            description="Sprzedajemy asortyment najwyższej jakości."
            user_icon={
              <FaMedal style={{ color: "#0275d8", fontSize: "25px" }} />
            }
          ></AdvertismentCard>
        </div>
      </div>

      <div className="row about-us-row ">
        <Alert variant="" className="text-center contact-info">
          <h1>Kilka słów o nas</h1>
          <hr style={{ border: "1px solid #0275d8" }} />
          {/* <p>
            Jesteśmy hurtownią opakowań będącą na rynku już od ponad 25 lat.
            Zajmujemy się sprzedażą artykułów gastronomicznych oraz
            opakowaniowych. Zaopatrujemy lokale takie jak pizzerie, restauracje,
            piekarnie, slepy mięsne oraz większe przedsiębiorstwa
            handlowo-usługowe. Zachęcamy do zapoznania się z naszą ofertą a
            także do bezpośredniego kontatku z naszą siedzibą. Jesteśmy pewni,
            że sprostamy oczekiwaniom każdego klienta.
          </p> */}
          <p>
            Dostarczamy rozwiązania opakowaniowe i artykuły gastronomiczne od
            ponad ćwierćwiecza. Nasze doświadczenie na rynku sprawia, że
            jesteśmy pewni, iż sprostamy oczekiwaniom nawet najbardziej
            wymagających klientów. Jako zaufana hurtownia opakowań i artykułów
            gastronomicznych, zaopatrujemy różnorodne lokale, takie jak urokliwe
            pizzerie, wykwintne restauracje, zapachem pieczywa nęcące piekarnie,
            zaprzyjaźnione sklepy mięsne oraz dynamiczne przedsiębiorstwa
            handlowo-usługowe.
          </p>

          <p>
            Nieustannie poszerzamy naszą ofertę, aby zapewnić Ci szeroki wybór i
            możliwości dostosowania się do Twoich indywidualnych potrzeb. Nasz
            zespół z pasją pomaga klientom w znalezieniu idealnych rozwiązań
            opakowaniowych, które podkreślają charakter Twojej marki i
            zapewniają niezapomniane wrażenia dla Twoich klientów.
          </p>

          <p>
            Zapraszamy Cię do zapoznania się z naszą różnorodną ofertą, w której
            znajdziesz najwyższej jakości opakowania, artykuły spożywcze oraz
            wszelkie niezbędne akcesoria gastronomiczne. Oferujemy szeroki wybór
            produktów, których cechuje nie tylko solidność i funkcjonalność, ale
            także estetyka, która wyróżni Twoje usługi na tle konkurencji.
          </p>

          <p>
            Jeśli masz jakiekolwiek pytania lub potrzebujesz doradztwa, nasz
            zespół z przyjemnością udzieli Ci wsparcia. Zachęcamy do
            bezpośredniego kontaktu z naszą siedzibą, gdzie nasi specjaliści są
            gotowi odpowiedzieć na Twoje pytania i znaleźć najlepsze rozwiązanie
            dla Twojego biznesu.
          </p>

          {/* <p>
            Razem tworzymy niesamowite doświadczenia dla Twoich klientów. Dołącz
            do naszej rodziny klientów zadowolonych z naszych produktów i usług.
            Jesteśmy przekonani, że nasza pasja, profesjonalizm i nieustanne
            dążenie do doskonałości sprawią, że staniesz się jednym z naszych
            wiernych partnerów biznesowych.
          </p> */}

          <p>
            Witamy w naszym świecie opakowań i artykułów gastronomicznych.
            Przygotuj się na niezapomnianą podróż do doskonałości!
          </p>
        </Alert>
      </div>

      <div className="row justify-content-center text-center recommended-row">
        <h2>Polecane produkty</h2>
        <Card style={{ width: "18rem", margin: "2%" }}>
          <Button variant="image" style={{ padding: 0 }}>
            <Card.Img
              className="c_image"
              variant="top"
              src={pojemnik_nd}
              alt="Pojemnik pod zgrzew"
            />
          </Button>
          <Card.Body>
            <Card.Title>Pojemnik do zgrzewu</Card.Title>
            <hr style={{ border: "1px solid #0275d8" }} />
            <Card.Text>
              <b>Długość:</b> 227 mm
              <br />
              <b>Szerokość:</b> 178 mm
              <br />
              <b>Wysokość:</b> 50 mm
              <br />
              <b>Materiał:</b> PP
              <br />
              <b>Liczba komór:</b> 1
            </Card.Text>
            {/* <Button variant="primary">Zobacz podobne</Button> */}
          </Card.Body>
        </Card>

        <Card style={{ width: "18rem", margin: "2%" }}>
          <Button variant="image">
            <Card.Img
              className="c_image"
              variant="top"
              src={kraft_1000}
              alt="Kraft"
            />
          </Button>
          <Card.Body>
            <Card.Title>Miseczka sałatkowa kraft</Card.Title>
            <hr style={{ border: "1px solid #0275d8" }} />
            <Card.Text>
              <b>Pojemność:</b> 1000 ml
              <br />
              <b>Średnica górna:</b> ∅149
              <br />
              <b>Wysokość:</b> 78 mm
              <br />
              <b>Materiał:</b> papier + PE
              <br />
              <b>Kolor:</b> Brązowy
            </Card.Text>
            {/* <Button variant="primary">Zobacz podobne</Button> */}
          </Card.Body>
        </Card>

        <Card style={{ width: "18rem", margin: "2%" }}>
          <Button variant="image">
            <Card.Img
              className="c_image"
              variant="top"
              src={torba_2}
              alt="Torba papierowa"
            />
          </Button>
          <Card.Body>
            <Card.Title>Torba papierowa</Card.Title>
            <hr style={{ border: "1px solid #0275d8" }} />
            <Card.Text>
              <b>Długość:</b> 180 mm
              <br />
              <b>Szerokość:</b> 80 mm
              <br />
              <b>Wysokość:</b> 225 mm
              <br />
              <b>Kolor:</b> Biały
              <br />
              <b>Pakowane po:</b> 25 sztuk
            </Card.Text>
            {/* <Button variant="primary">Zobacz podobne</Button> */}
          </Card.Body>
        </Card>
      </div>

      {/* <div className="row p-4">
                <div className="col">
                    <Alert variant="warning" className="text-center contact-info">
                        <Alert.Heading>Nasza oferta</Alert.Heading>
                        <hr />
                        <p>
                            Nasz asortyment składa się głównie z opakowań jednorazowych takich jak sztućce plastikowe, serwetki, kubeczki papierowe, czy opakowania obiadowe.
                            Ponadto w ofercie posiadamy torby papierowe, pojemniki na sos, kartony do pizzy, opakowania na torty a także folie bąbelkową, tekturę falistą oraz wiele innych.
                            Sprawdź naszą pełną ofertę klikając w przycisk poniżej.
                        </p>
                    </Alert>
                </div>

            </div> */}
    </Container>
  );
}
