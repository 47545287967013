import chinet_23 from "../images/talerze/chinet_23.jpg";
import chinet_owalny from "../images/talerze/chinet_owalny.jpg";
import chinet_kwadrat from "../images/talerze/chinet_kwadrat.jpg";
import talerz_papierowy from "../images/talerze/talerz_papierowy.jpg";
import talerz_plastikowy from "../images/talerze/talerz_plastikowy.jpg";

const products = [
  {
    id: 1,
    name: "Talerz chinet ∅17",
    diameter: "∅17",
    shape: "Okrągły",
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_23,
  },
  {
    id: 2,
    name: "Talerz chinet ∅23",
    diameter: "∅23",
    shape: "Okrągły",
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_23,
  },
  {
    id: 3,
    name: "Talerz chinet ∅26",
    diameter: "∅26",
    shape: "Okrągły",
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_23,
  },
  {
    id: 4,
    name: "Talerz chinet owalny mały ∅26",
    diameter: "∅26",
    shape: "Owalny",
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_owalny,
  },
  {
    id: 5,
    name: "Talerz chinet owalny duży ∅32",
    diameter: "∅32",
    shape: "Owalny",
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_owalny,
  },
  {
    id: 6,
    name: "Talerz chinet kwadratowy 15x15 cm",
    diameter: "15x15 cm",
    shape: "Kwadratowy",
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_kwadrat,
  },
  {
    id: 7,
    name: "Talerz chinet kwadratowy 26x26 cm",
    diameter: "26x26 cm",
    shape: "Kwadratowy",
    material: "Trzcina cukrowa",
    color: "Biały",
    amount: 50,
    image: chinet_kwadrat,
  },
  {
    id: 8,
    name: "Tacka tekturowa ∅15",
    diameter: "∅15",
    shape: "Okrągły",
    material: "Tektura",
    color: "Biały",
    amount: 100,
    image: talerz_papierowy,
  },
  {
    id: 9,
    name: "Tacka tekturowa ∅18",
    diameter: "∅18",
    shape: "Okrągły",
    material: "Tektura",
    color: "Biały",
    amount: 100,
    image: talerz_papierowy,
  },
  {
    id: 10,
    name: "Tacka tekturowa ∅22",
    diameter: "∅22",
    shape: "Okrągły",
    material: "Tektura",
    color: "Biały",
    amount: 100,
    image: talerz_papierowy,
  },
  {
    id: 11,
    name: "Tacka tekturowa ∅25",
    diameter: "∅25",
    shape: "Okrągły",
    material: "Tektura",
    color: "Biały",
    amount: 100,
    image: talerz_papierowy,
  },
  {
    id: 12,
    name: "Tacka tekturowa ∅33",
    diameter: "∅33",
    shape: "Okrągły",
    material: "Tektura",
    color: "Biały",
    amount: 100,
    image: talerz_papierowy,
  },
  {
    id: 13,
    name: "Tacka tekturowa ∅42",
    diameter: "∅42",
    shape: "Okrągły",
    material: "Tektura",
    color: "Biały",
    amount: 100,
    image: talerz_papierowy,
  },
  {
    id: 14,
    name: "Talerz plastikowy deserowy ∅17",
    diameter: "∅17",
    shape: "Okrągły",
    material: "Plastik",
    color: "Biały",
    amount: 100,
    image: talerz_plastikowy,
  },
  {
    id: 15,
    name: "Talerz plastikowy niedzielony ∅22",
    diameter: "∅22",
    shape: "Okrągły",
    material: "Plastik",
    color: "Biały",
    amount: 100,
    image: talerz_plastikowy,
  },
];

export { products };
