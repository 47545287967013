import zolta from "../images/reklamowki/zolta.jpg";
import zielona from "../images/reklamowki/zielona.jpg";
import biala from "../images/reklamowki/biala.jpg";
import czarna from "../images/reklamowki/czarna.jpg";
import rolka from "../images/reklamowki/rolka.jpg";
import kod from "../images/reklamowki/kod.jpg";

const products = [
  {
    id: 0,
    name: "Reklamówka foliowa na rolce",
    width: 22,
    height: 40,
    amount: "100 szt.",
    image: rolka,
  },
  {
    id: 1,
    name: "Reklamówka foliowa 22x40",
    width: 22,
    height: 40,
    amount: "100 szt.",
    image: biala,
  },
  {
    id: 2,
    name: "Reklamówka foliowa 25x43",
    width: 25,
    height: 43,
    amount: "100 szt.",
    image: zielona,
  },
  {
    id: 3,
    name: "Reklamówka foliowa 25x45 wzmacniana",
    width: 25,
    height: 45,
    amount: "100 szt.",
    image: zielona,
  },
  {
    id: 4,
    name: "Reklamówka foliowa 28x50",
    width: 28,
    height: 50,
    amount: "200 szt.",
    image: zolta,
  },
  {
    id: 5,
    name: "Reklamówka foliowa 30x55",
    width: 30,
    height: 55,
    amount: "100 szt.",
    image: biala,
  },
  {
    id: 6,
    name: "Reklamówka foliowa 30x55 wzmacniana",
    width: 30,
    height: 55,
    amount: "100 szt.",
    image: biala,
  },
  {
    id: 7,
    name: "Reklamówka foliowa 30x55 40μm z kodem",
    width: 30,
    height: 55,
    amount: "100 szt.",
    image: kod,
  },
  {
    id: 8,
    name: "Reklamówka foliowa 34x65",
    width: 34,
    height: 65,
    amount: "100 szt.",
    image: biala,
  },
  {
    id: 9,
    name: "Reklamówka foliowa 44x70",
    width: 44,
    height: 70,
    amount: "100 szt.",
    image: biala,
  },
  {
    id: 10,
    name: "Reklamówka foliowa 44x90 biała",
    width: 44,
    height: 90,
    amount: "100 szt.",
    image: biala,
  },
  {
    id: 11,
    name: "Reklamówka foliowa 44x90 czarna",
    width: 44,
    height: 90,
    amount: "100 szt.",
    image: czarna,
  },
];

export { products };
