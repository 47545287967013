import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Nav from "react-bootstrap/Nav";

import contact_style from "../css/contact_style.css";

export default function ProductHeader({ product_name }) {
  return (
    <div className="row mt-3 product-header-col ">
      <div className="col-auto my-array-col ">
        <Link to={"/produkty"}>
          <BsFillArrowLeftCircleFill
            style={{ color: "#0275d8", fontSize: "1.9rem" }}
          />
        </Link>
      </div>
      <div className="col product-header-font">
        <h1>{product_name}</h1>
      </div>
    </div>
  );
}
