import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Accordion from "react-bootstrap/Accordion";

import ProductHeader from "./ProductHeader.js";
import { Helmet } from "react-helmet";

import { products } from "../products/pojemniki_kraft_data.js";

export default function PojemnikiKraft() {
  const [show, setShow] = useState(false);
  const [image_to_display, set_image_to_display] = useState();
  const handleShow = (image_to_set) => {
    set_image_to_display(image_to_set);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [filtered_products, set_filtered_products] = useState(products);

  const map_products = (products_to_map) => {
    return products_to_map.map((product) => (
      <Card key={product.id} style={{ width: "18rem", margin: 20 }}>
        <Button
          variant="image"
          onClick={(e) => handleShow(product.image)}
          style={{ padding: 0 }}
        >
          <Card.Img
            variant="top"
            style={{ marginTop: 10 }}
            src={product.image}
            alt={product.name}
          />
        </Button>
        <Card.Body>
          <Card.Title>{product.name}</Card.Title>
          <hr />
          <Card.Text>
            {product.type === "pojemnik" && (
              <div>
                <b>Pojemność:</b> {product.capacity} ml
                <br />
                <b>Średnica górna:</b> ∅{product.diameter}
                <br />
                <b>Wysokość:</b> {product.height} mm
                <br />
                <b>Materiał:</b> {product.material}
                <br />
                <b>Kolor:</b> {product.color}
                <br />
                <b>Pakowane po:</b> {product.amount} sztuk
              </div>
            )}
            {product.type === "przykrywka" && (
              <div>
                <b>Średnica:</b> ∅{product.diameter}
                <br />
                <b>Pasuje na pojemnik:</b> {product.cup}
                <br />
                <b>Materiał:</b> {product.material}
                <br />
                <b>Kolor:</b> {product.color}
                <br />
                <b>Pakowane po:</b> {product.amount} sztuk
              </div>
            )}
          </Card.Text>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>
    ));
  };

  const [list_of_products, set_list_of_products] = useState(
    map_products(products)
  );

  const sort = (e) => {
    const option = document.getElementById("select_sort").value;

    // console.log("moja opcja" + option);
    var sorted_products;

    if (option === "1") {
      sorted_products = filtered_products.sort((p1, p2) =>
        p1.id >= p2.id ? 1 : -1
      );
    } else if (option === "2") {
      sorted_products = filtered_products.filter((p) => p.type == "pojemnik");
      sorted_products = sorted_products.sort((p1, p2) =>
        p1.capacity >= p2.capacity ? 1 : -1
      );
    } else if (option === "3") {
      sorted_products = filtered_products.filter((p) => p.type == "pojemnik");
      sorted_products = sorted_products.sort((p1, p2) =>
        p1.capacity >= p2.capacity ? -1 : 1
      );
    }

    set_list_of_products(map_products(sorted_products));
  };

  const filter = (e) => {
    const diameter = document.getElementById("select_diameter").value;

    const product_type = document.getElementById("select_product_type").value;

    const min_capacity = document
      .getElementById("form_min_capacity")
      .value.toUpperCase();
    const max_capacity = document
      .getElementById("form_max_capacity")
      .value.toUpperCase();

    var p = products.filter(
      (product) =>
        (min_capacity === "" ||
          (product.capacity !== null && product.capacity >= min_capacity)) &&
        (max_capacity === "" ||
          (product.capacity !== null && product.capacity <= max_capacity)) &&
        (diameter === "" || diameter == product.diameter) &&
        (product_type === "" || product_type == product.type)
    );

    set_filtered_products(p);
    set_list_of_products(map_products(p));
  };

  return (
    <div className="col container_column">
      <Helmet>
        <title>Pojemniki kraft - Hurtownia Opakowań Henryk Klimowicz</title>
        <meta
          name="description"
          content="Pojemniki kraft na sałatkę, zupę, zimne dania."
        />
        <link rel="canonical" href="/pojemniki-kraft" />
      </Helmet>

      <ProductHeader product_name="Pojemniki kraft"></ProductHeader>

      <div className="row my-accodrion-row">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Filtruj produkty</Accordion.Header>
            <Accordion.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Pojemność min.</InputGroup.Text>
                    <Form.Control
                      id="form_min_capacity"
                      aria-label="min_capacity"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Typ produktu</InputGroup.Text>
                    <Form.Select
                      id="select_product_type"
                      aria-label="select_product_type"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="pojemnik">Pojemniki</option>
                      <option value="przykrywka">Przykrywki</option>
                    </Form.Select>
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Sortowanie</InputGroup.Text>
                    <Form.Select id="select_sort" onChange={(e) => sort(e)}>
                      <option value="1">Domyślne</option>
                      <option value="2">Pojemność rosnąco</option>
                      <option value="3">Pojemność malejąco</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2">
                  <InputGroup className="mb-3" onChange={(e) => filter(e)}>
                    <InputGroup.Text>Pojemność max.</InputGroup.Text>
                    <Form.Control
                      id="form_max_capacity"
                      aria-label="max_capacity"
                    />
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <InputGroup className="mb-3">
                    <InputGroup.Text>Średnica</InputGroup.Text>
                    <Form.Select
                      id="select_diameter"
                      aria-label="select_diameter"
                      onChange={(e) => filter(e)}
                    >
                      <option value="">Wszystko</option>
                      <option value="97">97 mm</option>
                      <option value="115">115 mm</option>
                      <option value="149">149 mm</option>
                      <option value="184">184 mm</option>
                    </Form.Select>
                  </InputGroup>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row justify-content-center">{list_of_products}</div>

      <Modal show={show} onHide={handleClose} centered size="xl">
        <img src={image_to_display} className="img-fluid" alt="wzór" />
      </Modal>
    </div>
  );
}
