import nitryle_niebieskie from "../images/rekawiczki/nitryle_niebieskie.jpg";
import nitryle_czarne from "../images/rekawiczki/nitryle_czarne.jpg";
import lateksy from "../images/rekawiczki/lateksy.jpg";
import winyle from "../images/rekawiczki/winyle.jpg";
import zawieszka from "../images/rekawiczki/zawieszka.jpg";
import foliowe from "../images/rekawiczki/foliowe.jpg";
import wampirki from "../images/rekawiczki/wampirki.jpg";
import nakrapiane from "../images/rekawiczki/nakrapiane.jpg";
import dragony from "../images/rekawiczki/dragony.jpg";
import paluszki from "../images/rekawiczki/paluszki.jpg";
import piekarnicze from "../images/rekawiczki/piekarnicze.jpg";
import dlugi_mankiet from "../images/rekawiczki/dlugi_mankiet.jpg";

const products = [
  {
    id: 1,
    name: "Rękawiczki nitrylowe niebieskie",
    size: "S, M, L, XL",
    amount: "100 szt.",
    image: nitryle_niebieskie,
  },
  {
    id: 2,
    name: "Rękawiczki nitrylowe czarne",
    size: "S, M, L, XL",
    amount: "100 szt.",
    image: nitryle_czarne,
  },
  {
    id: 3,
    name: "Rękawiczki lateksowe białe",
    size: "S, M, L, XL",
    amount: "100 szt.",
    image: lateksy,
  },
  {
    id: 4,
    name: "Rękawiczki winylowe",
    size: "S, M, L, XL",
    amount: "100 szt.",
    image: winyle,
  },
  {
    id: 5,
    name: "Rękawiczki foliowe typu zawieszka",
    size: "M, L",
    amount: "100 szt.",
    image: zawieszka,
  },
  {
    id: 6,
    name: "Rękawiczki foliowe w kartoniku",
    size: "XL",
    amount: "500 szt.",
    image: foliowe,
  },
  {
    id: 7,
    name: "Rękawice robocze wampirki",
    size: "Uniwersalne",
    amount: "10 par",
    image: wampirki,
  },
  {
    id: 8,
    name: "Rękawice robocze nakrapiane",
    size: "Uniwersalne",
    amount: "12 par",
    image: nakrapiane,
  },
  {
    id: 9,
    name: "Rękawice robocze dragony",
    size: "8, 9, 10",
    amount: "12 par",
    image: dragony,
  },
  {
    id: 10,
    name: "Rękawice białe gumowe palce",
    size: "7, 8, 9, 10",
    amount: "12 par",
    image: paluszki,
  },
  {
    id: 11,
    name: "Rękawice piekarnicze",
    size: "Uniwersalne",
    amount: "1 para",
    image: piekarnicze,
  },
  {
    id: 12,
    name: "Rękawice gospodarcze długi mankiet",
    size: "S, M, L, XL",
    amount: "1 para",
    image: dlugi_mankiet,
  },
];

export { products };
