import "./App.css";
import "./css/slides_style.css";
import Navigation from "./components/Navigation.js";
import Start from "./components/Start.js";
import Contact from "./components/Contact.js";
import TorbyPapieroweUcho from "./components/TorbyPapieroweUcho.js";
import TorebkiPapierowe from "./components/TorebkiPapierowe";
import TorebkiKrzyzowe from "./components/TorebkiKrzyzowe";
import Products from "./components/Products.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import KartonyTransportowe from "./components/KartonyTransportowe";
import TaceKateringowe from "./components/TaceKateringowe";
import PojemnikiPodZgrzew from "./components/PojemnikiPodZgrzew";
import PrzykryciaDoZgrzewu from "./components/PrzykryciaDoZgrzewu";
import PojemnikiStyropianowe from "./components/PojemnikiStyropianowe";
import PojemnikiOkraglePodZgrzew from "./components/PojemnikiOkraglePodZgrzew";
import KubkiPapierowe from "./components/KubkiPapierowe";
import PojemnikiKraft from "./components/PojemnikiKraft";
import Talerze from "./components/Talerze";
import PodkladyPodTort from "./components/PodkladyPodTort";
import Pizza from "./components/Pizza";
import Katalog from "./components/Katalog";
import OpakowaniaPrzemyslowe from "./components/OpkowaniaPrzemyslowe";
import Container from "react-bootstrap/Container";
import Wiaderka from "./components/Wiaderka";
import PojemnikiCukiernicze from "./components/PojemnikiCukiernicze";
import PudelkaNaTorty from "./components/PudelkaNaTorty";
import ForemkiAluminiowe from "./components/ForemkiAluminiowe";
import PojemnikiSalatkowe from "./components/PojemnikiSalatkowe";
import TorebkiProzniowe from "./components/TorebkiProzniowe";
import Rekawiczki from "./components/Rekawiczki";
import ReklamowkiFoliowe from "./components/ReklamowkiFoliowe";
import Serwetki from "./components/Serwetki";
import MiseczkiNaZupe from "./components/MiseczkiNaZupe";
import Szukaj from "./components/Szukaj";
import ArtGospodarcze from "./components/ArtGospodarcze";
import Odziez from "./components/Odziez.js";
import ChemiaGospodarcza from "./components/ChemiaGospodarcza.js";
import PudelkaSushi from "./components/PudelkaSushi.js";
import Sprzatanie from "./components/Sprzatanie.js";
import Reczniki from "./components/Reczniki.js";
import KartonyKlapowe from "./components/KartonyKlapowe.js";
import OpakowaniaNaZywnosc from "./components/OpakowaniaNaZywnosc.js";
import Shake from "./components/Shake.js";

function App() {
  return (
    <Router>
      <Container fluid>
        <Navigation />
        <div className="row justify-content-center">
          <Routes>
            <Route path="/start" element={<Start />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/produkty" element={<Products />} />
            <Route path="/katalog" element={<Katalog />} />
            <Route
              path="/torby-papierowe-z-uchem"
              element={<TorbyPapieroweUcho />}
            />
            <Route path="/torebki-papierowe" element={<TorebkiPapierowe />} />
            <Route path="/torebki-krzyzowe" element={<TorebkiKrzyzowe />} />
            <Route
              path="/kartony-transportowe"
              element={<KartonyTransportowe />}
            />
            <Route path="/tace-kateringowe" element={<TaceKateringowe />} />
            <Route
              path="/pojemniki-pod-zgrzew"
              element={<PojemnikiPodZgrzew />}
            />
            <Route
              path="/przykrycia-do-pojemnikow-pod-zgrzew"
              element={<PrzykryciaDoZgrzewu />}
            />
            <Route
              path="/pojemniki-styropianowe"
              element={<PojemnikiStyropianowe />}
            />
            <Route
              path="/pojemniki-okragle-pod-zgrzew"
              element={<PojemnikiOkraglePodZgrzew />}
            />
            <Route path="/kubeczki-papierowe" element={<KubkiPapierowe />} />
            <Route path="/pojemniki-kraft" element={<PojemnikiKraft />} />
            <Route path="/talerze" element={<Talerze />} />
            <Route path="/podklady-pod-tort" element={<PodkladyPodTort />} />
            <Route path="/pizza" element={<Pizza />} />
            <Route
              path="/opakowania-przemyslowe"
              element={<OpakowaniaPrzemyslowe />}
            />
            <Route path="/wiaderka" element={<Wiaderka />} />
            <Route
              path="/pojemniki-cukiernicze"
              element={<PojemnikiCukiernicze />}
            />
            <Route path="/pudelka-na-torty" element={<PudelkaNaTorty />} />
            <Route path="/foremki-aluminiowe" element={<ForemkiAluminiowe />} />
            <Route
              path="/pojemniki-salatkowe"
              element={<PojemnikiSalatkowe />}
            />
            <Route path="/torebki-prozniowe" element={<TorebkiProzniowe />} />
            <Route path="/rekawiczki" element={<Rekawiczki />} />
            <Route path="/reklamowki-foliowe" element={<ReklamowkiFoliowe />} />
            <Route path="/serwetki" element={<Serwetki />} />
            <Route path="/miseczki-na-zupe" element={<MiseczkiNaZupe />} />
            <Route path="/artykuly-gospodarcze" element={<ArtGospodarcze />} />
            <Route path="/odziez-gastronomiczna" element={<Odziez />} />
            <Route path="/szukaj" element={<Szukaj />} />
            <Route path="/chemia-gospodarcza" element={<ChemiaGospodarcza />} />
            <Route path="/pudelka-na-sushi" element={<PudelkaSushi />} />
            <Route path="/sprzatanie" element={<Sprzatanie />} />
            <Route path="/reczniki" element={<Reczniki />} />
            <Route path="/kartony-klapowe" element={<KartonyKlapowe />} />
            <Route path="/shake-lody" element={<Shake />} />
            <Route
              path="/kartoniki-koperty-owijki"
              element={<OpakowaniaNaZywnosc />}
            />

            <Route path="/" element={<Contact />} />
            {/* <Route path="/" element={<Navigate to="/kontakt" />} /> */}
            <Route
              path="*"
              element={
                <div>
                  <h2>Nie znaleziono strony</h2>
                </div>
              }
            />
          </Routes>
        </div>
      </Container>
    </Router>
  );
}

export default App;
