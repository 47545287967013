import pet200 from "../images/shake/pet200.jpg";
import pet250 from "../images/shake/pet250.jpg";
import pet350w from "../images/shake/pet350w.jpg";
import pet350s from "../images/shake/pet350s.jpg";
import pet400 from "../images/shake/pet400.jpg";

import kubek_7930 from "../images/shake/kubek_7930.jpg";
import kubek_7940 from "../images/shake/kubek_7940.jpg";
import kubek_260 from "../images/shake/kubek_260.jpg";

import pokrywka_z_otworem_82 from "../images/shake/pokrywka_z_otworem_82.jpg";
import pokrywka_z_otworem_99 from "../images/shake/pokrywka_z_otworem_99.jpg";
import pokrywka_bez_otworu_82 from "../images/shake/pokrywka_bez_otworu_82.jpg";
import pokrywka_bez_otworu_99 from "../images/shake/pokrywka_bez_otworu_99.jpg";
import pokrywka_plaska_82 from "../images/shake/pokrywka_plaska_82.jpg";
import pokrywka_plaska_99 from "../images/shake/pokrywka_plaska_99.jpg";

import miseczka_lody from "../images/shake/miseczka_lody.jpg";
import lopatka_fluor from "../images/shake/lopatka_fluor.jpg";
import lopatka_drewno from "../images/shake/lopatka_drewno.jpg";

const products = [
  {
    id: 0,
    name: "Kubek PET na shake 400ml Ø95mm",
    image: pet400,
  },
  {
    id: 1,
    name: "Kubek PET na shake 350ml Ø95mm",
    image: pet350s,
  },
  {
    id: 2,
    name: "Kubek PET na shake 350ml Ø78mm",
    image: pet350w,
  },
  {
    id: 3,
    name: "Kubek PET na shake 250ml Ø78mm",
    image: pet250,
  },
  {
    id: 4,
    name: "Kubek PET na shake 200ml Ø78mm",
    image: pet200,
  },
  {
    id: 5,
    name: "Pokrywka do kubka shake wypukła z otworem Ø99",
    image: pokrywka_z_otworem_99,
  },
  {
    id: 6,
    name: "Pokrywka do kubka shake wypukła z otworem Ø82",
    image: pokrywka_z_otworem_82,
  },
  {
    id: 7,
    name: "Pokrywka do kubka shake wypukła bez otworu Ø99",
    image: pokrywka_bez_otworu_99,
  },
  {
    id: 8,
    name: "Pokrywka do kubka shake wypukła bez otworu Ø82",
    image: pokrywka_bez_otworu_82,
  },
  {
    id: 9,
    name: "Pokrywka do kubka shake płaska Ø99",
    image: pokrywka_plaska_99,
  },
  {
    id: 10,
    name: "Pokrywka do kubka shake płaska Ø82",
    image: pokrywka_plaska_82,
  },
  {
    id: 11,
    name: "Miseczka na lody 160ml",
    image: miseczka_lody,
  },
  {
    id: 12,
    name: "Miseczka na lody 245ml",
    image: miseczka_lody,
  },
  {
    id: 13,
    name: "Miseczka na lody 360ml",
    image: miseczka_lody,
  },
  {
    id: 14,
    name: "Łopatka do lodów fluor",
    image: lopatka_fluor,
  },
  {
    id: 15,
    name: "Łopatka do lodów drewniana",
    image: lopatka_drewno,
  },
  {
    id: 16,
    name: "Kubek RPET 7930 300ml Ø95mm",
    image: kubek_7930,
  },
  {
    id: 17,
    name: "Kubek RPET 7940 400ml Ø95mm",
    image: kubek_7940,
  },
  {
    id: 18,
    name: "Kubek RPET 260ml Ø95mm",
    image: kubek_260,
  },
];

export { products };
