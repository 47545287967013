import hamburger_gigant from "../images/opakowania_na_zywnosc/hamburger_gigant.jpg";
import hamburger_sredni from "../images/opakowania_na_zywnosc/hamburger_sredni.jpg";
import kurczak_duzy from "../images/opakowania_na_zywnosc/kurczak_duzy.jpg";
import kurczak_maly from "../images/opakowania_na_zywnosc/kurczak_maly.jpg";
import lunch_box from "../images/opakowania_na_zywnosc/lunch_box.jpg";
import kebab_box from "../images/opakowania_na_zywnosc/kebab_box.jpg";
import kebab_gazeta from "../images/opakowania_na_zywnosc/kebab_gazeta.jpg";
import koperta_smacznego from "../images/opakowania_na_zywnosc/koperta_smacznego.jpg";
import kebab_kucharz from "../images/opakowania_na_zywnosc/kebab_kucharz.jpg";
import hotdog_certyfikat from "../images/opakowania_na_zywnosc/hotdog_certyfikat.jpg";
import hotdog_folia from "../images/opakowania_na_zywnosc/hotdog_folia.jpg";
import koperta_frytka from "../images/opakowania_na_zywnosc/koperta_frytka.jpg";
import koperta_frytka_gazeta from "../images/opakowania_na_zywnosc/koperta_frytka_gazeta.jpg";
import kubelek13 from "../images/opakowania_na_zywnosc/kubelek13.jpg";
import szachownica from "../images/opakowania_na_zywnosc/szachownica.jpg";
import tortilla from "../images/opakowania_na_zywnosc/tortilla.jpg";
import kubelek_na_kurczaka from "../images/opakowania_na_zywnosc/kubelek_na_kurczaka.jpg";

const products = [
  {
    id: 0,
    name: "Kartonik hamburger gigant 150x150x80",
    type: "kartonik",
    image: hamburger_gigant,
  },
  {
    id: 1,
    name: "Kartonik hamburger średni 115x115x70",
    type: "kartonik",
    image: hamburger_sredni,
  },
  {
    id: 2,
    name: "Kartonik kurczak duży 220x120x75",
    type: "kartonik",
    image: kurczak_duzy,
  },
  {
    id: 3,
    name: "Kartonik kurczak mały 160x100x60",
    type: "kartonik",
    image: kurczak_maly,
  },
  {
    id: 4,
    name: "Lunch box 700ml",
    type: "kubelek",
    image: lunch_box,
  },
  {
    id: 5,
    name: "Kebab box 500/750/1000ml",
    type: "kubelek",
    image: kebab_box,
  },
  {
    id: 6,
    name: "Koperta na kebaba 'smacznego'",
    type: "koperta",
    image: koperta_smacznego,
  },
  {
    id: 7,
    name: "Koperta na kebaba 'gazeta'",
    type: "koperta",
    image: kebab_gazeta,
  },
  {
    id: 8,
    name: "Koperta na kebaba 'kucharz'",
    type: "koperta",
    image: kebab_kucharz,
  },
  {
    id: 9,
    name: "Koperta hot-dog certyfikat",
    type: "koperta",
    image: hotdog_certyfikat,
  },
  {
    id: 10,
    name: "Koperta hot-dog",
    type: "koperta",
    image: hotdog_folia,
  },
  {
    id: 11,
    name: "Koperta na frytki 250gr",
    type: "koperta",
    image: koperta_frytka,
  },
  {
    id: 12,
    name: "Koperta na frytki gazeta 130/200gr",
    type: "koperta",
    image: koperta_frytka_gazeta,
  },
  {
    id: 13,
    name: "Kubełek na kurczaka 1.3L",
    type: "kubelek",
    image: kubelek13,
  },
  {
    id: 14,
    name: "Papier 'szachownica'",
    type: "papierek",
    image: szachownica,
  },
  {
    id: 15,
    name: "Kartonik tortilla",
    type: "kartonik",
    image: tortilla,
  },
  {
    id: 16,
    name: "Kubełek na kurczaka 2,4L",
    type: "kubelek",
    image: kubelek_na_kurczaka,
  },
];

export { products };
